export type DateString = string; // "YYYY-MM-DD"
export const millisecondsPerDay = 1000 * 60 * 60 * 24;

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export function getMonthName(date: DateString): string {
  return getMonthNameFromRawDate(dateFromString(date));
}

export function getMonthNameFromRawDate(date: Date): string {
  const monthIndex = date.getMonth();
  switch (monthIndex) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
    default:
      throw new Error(`ERROR: Unexpected month index: ${monthIndex}`);
  }
}

export function getDayOfMonth(date: DateString): number {
  return dateFromString(date).getDate();
}

export function getYear(date: DateString): number {
  return dateFromString(date).getFullYear();
}

export function getDateForMonth(monthName: string, year: number): string {
  return createDateString(year, getMonthIndex(monthName) + 1, 1);
}

export function getMonthIndex(monthName: string): number {
  switch (monthName) {
    case "January":
      return 0;
    case "February":
      return 1;
    case "March":
      return 2;
    case "April":
      return 3;
    case "May":
      return 4;
    case "June":
      return 5;
    case "July":
      return 6;
    case "August":
      return 7;
    case "September":
      return 8;
    case "October":
      return 9;
    case "November":
      return 10;
    case "December":
      return 11;
    default:
      throw new Error(`ERROR: Unexpected month name: ${monthName}`);
  }
}

export function createDateString(year: number, month: number, day: number): DateString {
  const yearString = year.toString();
  const monthString = month.toString().padStart(2, '0');
  const dayString = day.toString().padStart(2, '0');
  return `${yearString}-${monthString}-${dayString}`;
}

export function dateStringFromDate(date: Date): DateString {
  // note that month is zero based
  return createDateString(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

export function splitDateString(date: DateString): {year: number, month: number; day: number} | undefined {
  const match = date.match(/^(?<year>\d\d\d\d)-(?<month>\d\d)-(?<day>\d\d)/);
  if (!match || !match.groups) {
    return;
  }
  const {year, month, day} = match.groups;
  // note that month is zero based
  return {year: Number(year), month: Number(month) - 1, day: Number(day)};
}

function isValidDateString(dateString: DateString): boolean {

  const {year, month, day} = {...splitDateString(dateString)};

  //@ts-ignore
  return (month <= 11 && day <= 31 && year >= 0 && month >= 0 && day >= 1 && year.toString().length === 4);
}

export function validateDateString(date: DateString) {
  if (!splitDateString(date)) {
    throw new Error(`Malformed date string "${date}", expecting "YYYY-MM-DD"`);
  }
  if (!isValidDateString(date)) {
    throw new Error(`Invalid date string "${date}"`);
  }
}

export function dateFromString<T extends DateString | undefined>(date: T): T extends DateString ? Date : undefined
export function dateFromString(date: DateString | undefined): Date | undefined {
  if (!date) {
    return;
  }
  const match = splitDateString(date);
  if (!match) {
    return;
  }
  return new Date(match.year, match.month, match.day);
}

export const DaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function getDayOfWeekFromDateString(dateString: DateString): string {
  const date = dateFromString(dateString);
  return DaysOfWeek[date.getDay()];
}

// Gives the date of the specified day of the week relative to the current date.
// Thus, if today is 11/27/2024, a Wednesday, then asking for a Tuesday (dayIndex = 2) will yield 11/26/2024.
export function getDateOfDay(dayIndex: number, {treatSundayAsLast}: {treatSundayAsLast?: boolean} = {}): DateString {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay() + dayIndex - (treatSundayAsLast && date.getDay() === 0 ? 7 : 0));
  return dateStringFromDate(date);
}

export function thisSundayDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return getDateFromOffset(date.getDay() === 0 ? 0 : 7 - date.getDay(), forDate);
}

export function nextSundayDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return getDateFromOffset(7 - date.getDay() + (date.getDay() === 0 ? 0 : 7), forDate);
}

export function lastSundayDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return getDateFromOffset(-date.getDay() - (date.getDay() === 0 ? 7 : 0), forDate);
}

function getDateFromOffset(days: number, forDate: DateString | undefined): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  date.setDate(date.getDate() + days);
  return dateStringFromDate(date);
}

export function addDayOffset(dateString: DateString, days: number): DateString {
  const date = dateFromString(dateString);
  date.setDate(date.getDate() + days);
  return dateStringFromDate(date);
}

export function getDayOfWeek(date: DateString): DayOfWeek {
  return dateFromString(date).getDay();
}

export function thisMonthDate(forDate?: DateString): DateString {
  const date = forDate ? dateFromString(forDate) : new Date();
  return createDateString(date.getFullYear(), date.getMonth() + 1, 1);
}

export function todayDate(): DateString {
  return dateStringFromDate(new Date());
}

export function getTomorrowsDateForPST(): DateString {
  // PST = UTC-8 (timezoneOffset = 8)
  const timezoneOffsetPST = 8;
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const tomorrowPST = new Date(new Date().getTime() + (timezoneOffset - timezoneOffsetPST) * 60 * 60 * 1000);
  tomorrowPST.setDate(tomorrowPST.getDate() + 1);
  return dateStringFromDate(tomorrowPST);
}

export function daysBetween(first: DateString, last: DateString): number {
  return Math.round((Number(dateFromString(last)) - Number(dateFromString(first))) / millisecondsPerDay);
}

export function sortDates(dates: DateString[]): DateString[] {
  return [...dates].sort((lhs, rhs) => lhs.localeCompare(rhs));
}

export function minDate(dates: DateString[]): DateString {
  if (dates.length === 0) {
    throw new Error('Cannot take minimum of an empty list');
  }
  return sortDates(dates)[0];
}

export function maxDate(dates: DateString[]): DateString {
  if (dates.length === 0) {
    throw new Error('Cannot take minimum of an empty list');
  }
  return sortDates(dates)[dates.length - 1];
}

// from https://stackoverflow.com/a/6117889/998490
export function getWeekNumber(date: DateString): number {
  const dateCopy = dateFromString(date);
  const dayNum = dateCopy.getUTCDay() || 7;
  dateCopy.setUTCDate(dateCopy.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(dateCopy.getUTCFullYear(),0,1));
  return Math.ceil((((Number(dateCopy) - Number(yearStart)) / 86400000) + 1)/7)
}

export function firstDayOfWeek(forDate: DateString = todayDate()): DateString {
  return addDayOffset(forDate, -dateFromString(forDate).getDay());
}

export function minDateAbsolute(): DateString {
  return createDateString(1500, 1, 1);
}

export function maxDateAbsolute(): DateString {
  return createDateString(3000, 1, 1);
}
