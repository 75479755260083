import {Church, SchoolTerm} from '../../common/model';
import {
  DateString,
  firstDayOfWeek,
  getDayOfMonth,
  getMonthName,
  getWeekNumber,
} from '../../common/date_string';

export function getWeeklyListName(church: Church | undefined, date: DateString) {
  const currentTerm = church?.schoolTerms && getTermForDate(church.schoolTerms, date);
  if (!currentTerm) {
    return `Week of ${getMonthName(date)} ${getDayOfMonth(date)}`;
  }
  const {name, startDate} = currentTerm;
  let week: number;
  const firstSundayOfTerm = firstDayOfWeek(startDate);
  week = getWeekNumber(date) - getWeekNumber(firstSundayOfTerm) + 1;
  return `${name ? name + ' - ' : ''}Week ${week}`
}

function getTermForDate(schoolTerms: SchoolTerm[], date: DateString): SchoolTerm | undefined {
  if (schoolTerms?.length) {
    let result = schoolTerms[0];
    for (const term of schoolTerms) {
      if (term.startDate <= date && term.startDate > result.startDate) {
        result = term;
      }
    }
    return result;
  }
}
