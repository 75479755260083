import React from "react";
import './song_info.css'
import {Hymn} from "../../common/model";
import {ReactComponent as BackArrow} from "../assets/arrow-previous-left-icon.svg";

interface Props {
  hymn: Hymn;
  back: () => void;
}

export const SongInfo = (props: Props) => {
  return (
    <div style={{position: "relative"}}>
      <div onClick={() => props.back()}><BackArrow className="backArrow"/></div>
      <div className='allInfo'>
        {props.hymn.author &&
          (<div className="infoChunk">
            <div className="category">Author</div>
            <div className="info">{props.hymn.author}</div>
          </div>)}
        {props.hymn.composer &&
          (<div className="infoChunk">
            <div className="category">Composer</div>
            <div className="info">{props.hymn.composer}</div>
          </div>)}
        {props.hymn.harmony &&
          (<div className="infoChunk">
            <div className="category">Harmony</div>
            <div className="info">{props.hymn.harmony}</div>
          </div>)}
        {props.hymn.copyright &&
          (<div className="infoChunk">
            <div className="category">Copyright</div>
            <div className="info">{props.hymn.copyright}</div>
          </div>)}
      </div>
    </div>
  );
}
