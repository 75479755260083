import {addDayOffset, thisMonthDate, thisSundayDate} from '../../common/date_string';
import {SongList, SongListType} from '../../common/model';
import {getSongSlug} from '../../common/paths';

export function getDemoSongList(): SongList[] {
  return [
    {
      date: thisMonthDate(),
      type: SongListType.HymnOfTheMonth,
      songs: [
        {row: 1, slug: getSongSlug({songNumber: '679', hymnalSlug: 'tr2022'})}, // A Mighty Fortress Is Our God
      ]
    },
    {
      date: thisSundayDate(),
      type: SongListType.WorshipService,
      songs: [
        {row: 1, slug: getSongSlug({songNumber: '645', hymnalSlug: 'tr2022'})}, // How Firm a Foundation
        {row: 2, slug: getSongSlug({songNumber: '681', hymnalSlug: 'tr2022'})}, // Come, Thou Fount
        {row: 3, slug: getSongSlug({songNumber: '897', hymnalSlug: 'tr2022'})}, // Joy to the World!
        {row: 4, slug: getSongSlug({songNumber: '1076', hymnalSlug: 'tr2022'})}, // Doxology
      ]
    },
    {
      date: addDayOffset(thisSundayDate(), 3),
      type: SongListType.Event,
      name: 'Psalm Sing',
      time: '6pm',
      songs: [
        {row: 1, slug: getSongSlug({songNumber: '75', hymnalSlug: 'tr2022'})}, // Ps 23: The King of Love My Shepherd Is
        {row: 2, slug: getSongSlug({songNumber: '75', hymnalSlug: 'tr2022'})}, // Ps 6: Chide Me, O LORD, No Longer
      ]
    }
  ];
}
