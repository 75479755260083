import autoBind from 'auto-bind';
import {createAudioContext} from './instrument';
import {Voice, VoiceValues} from "../data/client_local_storage";
import {encodeMusicURI, generatePathFor} from "../util/path";
import {vocalsDir} from "../../common/paths";
import {ensureExists} from "../../common/util";
import {NetworkError} from '../util/network';

const defaultTempo = 100;

export class VocalsPlayer {
  audioContext = createAudioContext();
  initialTime: string | undefined = '0:00';
  mostRecentTick: number | undefined;
  isFinishedLoading: boolean = false;
  playing?: boolean;
  initialTempo = defaultTempo;
  urlPrefix: string;
  silentNode: OscillatorNode | undefined;
  songReady: (tempo: number) => void;
  onNetworkError: (networkError: NetworkError) => void;
  endOfFile: () => void;
  voiceValues: VoiceValues;
  buffers: {[voice: string]: AudioBuffer} = {};
  // TODO(hewitt): Why are we not using standard AudioElements for vocals instead of web audio
  //               Switching to AudioElements would be easy & may provide background playback
  nodes: {[voice: string]: {source: AudioBufferSourceNode; gain: GainNode}} = {}
  _startedAt: number = 0;
  _currentPercent: number = 0; // use _startedAt if playing is true
  updateLocationSlider: (() => void) | undefined;

  constructor(
    hymnalName: string,
    songName: string,
    songReady: (tempo: number) => void,
    onNetworkError: (networkError: NetworkError) => void,
    endOfFile: () => void,
    voiceValues: VoiceValues,
    updateLocationSlider?: () => void,
  ) {
    autoBind(this);

    const vocalsPath = `/${vocalsDir}`;
    this.urlPrefix = generatePathFor(vocalsPath, ensureExists(hymnalName), songName) + '/' + encodeMusicURI(songName);

    this.songReady = songReady;
    this.onNetworkError = onNetworkError;
    this.endOfFile = endOfFile;
    this.updateLocationSlider = updateLocationSlider;
    this.voiceValues = voiceValues;

    // TODO(hewitt): Catch end of file event for Web Audio node
    // this.midiPlayer.on('endOfFile',  this._onEndOfFile.bind(this));

    void this._loadMP3Vocals();
  }

  private _getMP3URL(voice: Voice) {
    const suffixMap = {
      [Voice.Soprano]: ' Soprano.mp3',
      [Voice.Alto]: ' Alto.mp3',
      [Voice.Tenor]: ' Tenor.mp3',
      [Voice.Bass]: ' Bass.mp3',
      [Voice.Piano]: ' Piano.mp3',
    };
    return this.urlPrefix + encodeMusicURI(suffixMap[voice]);
  }

  private async _loadMP3Vocals() {
    const promises: Promise<void>[] = [];
    for (const voice of Object.values(Voice)) {
      promises.push(this._downloadMP3(voice));
    }
    await Promise.all(promises);

    this.isFinishedLoading = true;
    this.songReady(this.initialTempo);
  }

  private async _downloadMP3(voice: Voice): Promise<void> {
    const response = await fetch(this._getMP3URL(voice));
    if (!response.ok) {
      this.onNetworkError(NetworkError.MP3DownloadFailed);
      return;
    }
    const buffer = await response.arrayBuffer();
    this.buffers[voice] = await this.audioContext.decodeAudioData(buffer);
  }

  private _createAudioNodes() {
    this.nodes = {};
    for (const voice of Object.values(Voice)) {
      const source = this.audioContext.createBufferSource();
      const gainNode = this.audioContext.createGain();
      source.buffer = this.buffers[voice]; // TODO(hewitt): Could the infinite value be in this buffer?
      source.connect(gainNode);
      gainNode.connect(this.audioContext.destination);
      gainNode.gain.setValueAtTime(guardAgainstInfiniteValue(this.voiceValues[voice], 1), 0);
      this.nodes[voice] = {source, gain: gainNode};
    }
  }

  refreshVoiceValues(voiceValues: VoiceValues): void {
    this.voiceValues = voiceValues;
    this.onVolumeChange();
  }

  private _onEndOfFile() {
    this.mostRecentTick = undefined;
    if (this.playing) {
      // this.audioElements.forEach(element => element.pause());
      setTimeout(() => {
        // user may have paused the player at the end of the song, in which case we don't want to restart it
        this.endOfFile()
      }, 100);
    }
  }

  restart() {
    // TODO(hewitt): Why do we not reset the audio elements to zero here?
    // this.audioElements.forEach(element => element.currentTime = 0);
    this.updateLocationSlider?.();
  }

  get duration(): number {
    const buffer = this.buffers[Voice.Soprano];
    return buffer?.duration ?? 0;
  }

  setLocationPercentage(percent: number) {
    // this.audioElements.forEach(element => element.currentTime = this.duration * (percent / 100));
  }

  getTempoValue(): number {
    return 0;
  }

  setDefaultTempo(_tempo: number) {
  }

  setNewTime(value: number) {
    const sliderValue = value / 100;
    const isPlaying = this.playing;
    if (isPlaying) {
      this.pause();
    }
    this._currentPercent = sliderValue * 100;
    if (isPlaying) {
      this.play();
    }
  }

  setPlaybackRate(percentage: number) {
    // TODO(hewitt): can the playback rate of a web audio element be changed?
    // this.audioElement.setPlaybackRate(percentage);
  }

  onTempoChange() {
    this.onTempoOrVolumeChange();
  }

  onVolumeChange() {
    this.onTempoOrVolumeChange();
  }

  onTempoOrVolumeChange() {
    if (this.playing) {
      for (const voice of Object.values(Voice)) {
        this.nodes[voice].gain.gain.setValueAtTime(guardAgainstInfiniteValue(this.voiceValues[voice], 1), 0);
      }
    }
  }

  getCurrentPercent(): number {
    if (this.duration === 0) {
      return 0;
    }
    if (this.playing) {
      return Math.min(100, (this.audioContext.currentTime - this._startedAt) / this.duration * 100);
    }
    return this._currentPercent;
  }

  play({endOfFile}: {endOfFile?: boolean} = {}) {
    const currentLocation = (this.getCurrentPercent() / 100) * this.duration;
    this.playing = true;
    if (!endOfFile) {
      this._startSilence();
    }
    this.mostRecentTick = undefined;

    // cannot call start on the same source node twice, so recreate all the nodes
    this._createAudioNodes();
    for (const {source} of Object.values(this.nodes)) {
      source.start(0, guardAgainstInfiniteValue(currentLocation, 0));
    }
    this._startedAt = this.audioContext.currentTime - currentLocation;
  }

  pause({endOfFile, userAction}: {endOfFile?: boolean, userAction?: boolean} = {}) {
    // Would need to record the pause time for subsequent restart
    // See this website:  https://stackoverflow.com/a/18562855/998490
    for (const {source} of Object.values(this.nodes)) {
      source.stop();
    }
    this._currentPercent = this.getCurrentPercent();

    this._stopSilence();
    this.playing = false;
  }

  // On iOS, we must begin playing a note right away when the play button is pressed
  // Thus, we play a silent note for the duration of the song - until pause is clicked
  private _startSilence() {
    this._stopSilence();
    this.silentNode = this.audioContext.createOscillator();
    this.silentNode.type = 'sine';
    this.silentNode.frequency.setValueAtTime(200, guardAgainstInfiniteValue(this.audioContext.currentTime, 0));
    const silentGain = this.audioContext.createGain();
    silentGain.gain.setValueAtTime(0, guardAgainstInfiniteValue(this.audioContext.currentTime, 0));
    silentGain.connect(this.audioContext.destination);
    this.silentNode.connect(silentGain);
    this.silentNode.start();
  }

  private _stopSilence() {
    if (this.silentNode) {
      this.silentNode.stop();
      this.silentNode = undefined;
    }
  }


  teardown() {
    // stop music when back button is pressed
    if (this.playing) {
      this.pause();
    }
  }
}

// An attempt to fix the following crash (happens very frequently when vocals are enabled, but repro still unknown):
//   Describe circumstances: {
//     column = 65528;
//     error = "{\"hasBeenCaught\":true}";
//     line = 2;
//     message = "TypeError: The provided value is non-finite";
//     url = "https://singyourpart.app/static/js/main.722bf2ad.js";
//   }
// The solution below is based on this Stack Overflow post: https://stackoverflow.com/a/76974623/998490
function guardAgainstInfiniteValue(value: any, defaultValue: number): number {
  if (typeof value !== 'number' || isNaN(value) || !isFinite(value)) {
    return defaultValue;
  }
  return value as number;
}
