import {HymnsByOrg} from './engineering_song_list';
import {useEffect, useState} from 'react';
import {useCustomMusic} from '../../../../data/use_custom_music';
import {useHymnals} from '../../../../data/use_hymnals';
import {useChurches} from '../../../../data/use_churches';
import {useLocalStorage} from '../../../../data/use_local_storage';
import {LocalStorageKey} from '../../../../data/client_local_storage';
import * as server_api from '../../../../../common/server_api';
import {thisSundayDate} from '../../../../../common/date_string';
import {Hymn, isBound, SongListType} from '../../../../../common/model';
import {getHymnFromSlug, getMidiUrlForHymn, getXmlUrlForHymn} from '../../../../util/path';

export function useBrokenSongsForThisSunday(): {brokenSongsByOrg: HymnsByOrg, sundaysPopulated: boolean} {
  const [brokenSongsByOrg, setBrokenSongsByOrg] = useState<HymnsByOrg>({});
  const [sundaysPopulated, setSundaysPopulated] = useState(false);
  const customMusic = useCustomMusic();
  const hymnals = useHymnals();
  const churches = useChurches();
  const [, setHymnalsHash] = useLocalStorage(LocalStorageKey.HymnalsHash);

  useEffect(() => {
    setHymnalsHash(undefined); // redownload all songs to get latest versions
  }, [setHymnalsHash]);

  useEffect(() => {
    if (churches.length === 0) {
      return;
    }
    void (async () => {
      let brokenHymns: HymnsByOrg = {}; // can't depend on state updates
      for (const church of churches) {
        const songLists = await server_api.getSongLists(church.id, thisSundayDate());
        for (const {date, type, songs} of songLists) {
          if (type === SongListType.WorshipService && date === thisSundayDate()) {
            for (const songListEntry of songs) {
              if (!isBound(songListEntry)) {
                continue
              }
              const hymn = getHymnFromSlug({songSlug: songListEntry.slug, hymnals, customMusic});
              if (!hymn) {
                continue;
              }
              brokenHymns = await addHymnIfBroken({
                hymn,
                orgId: church.id,
                brokenHymns,
                setBrokenHymns: setBrokenSongsByOrg,
              });
            }
          }
        }
      }
      setSundaysPopulated(true);
    })();
  }, [churches, hymnals, customMusic]);
  return {brokenSongsByOrg, sundaysPopulated};
}

export function useBrokenCustomMusic(): {brokenCustomMusicOrg: HymnsByOrg, customMusicPopulated: boolean} {
  const [brokenCustomMusicOrg, setBrokenCustomMusicOrg] = useState<HymnsByOrg>({});
  const [customMusicPopulated, setCustomMusicPopulated] = useState(false);
  const customMusic = useCustomMusic();
  const hymnals = useHymnals();
  const churches = useChurches();
  const [, setHymnalsHash] = useLocalStorage(LocalStorageKey.HymnalsHash);

  useEffect(() => {
    setHymnalsHash(undefined); // redownload all songs to get latest versions
  }, [setHymnalsHash]);

  useEffect(() => {
    if (churches.length === 0) {
      return;
    }
    void (async () => {
      let brokenHymns: HymnsByOrg = {}; // can't depend on state updates
      for (const church of churches) {
        const customHymns = customMusic[church.id];
        for (const hymn of customHymns ?? []) {
          brokenHymns = await addHymnIfBroken({
            hymn,
            orgId: church.id,
            brokenHymns,
            setBrokenHymns: setBrokenCustomMusicOrg,
            checkForXmlFile: true,
          });
        }
      }
      setCustomMusicPopulated(true);
    })();
  }, [churches, hymnals, customMusic]);
  return {brokenCustomMusicOrg, customMusicPopulated};
}

async function addHymnIfBroken({hymn, orgId, brokenHymns, setBrokenHymns, checkForXmlFile}: {
  hymn: Hymn,
  orgId: number,
  brokenHymns: HymnsByOrg,
  setBrokenHymns: (hymnsByOrg:HymnsByOrg) => void,
  checkForXmlFile?: boolean,
}) {
  const midiUrl = getMidiUrlForHymn(hymn);
  const response = await fetch(midiUrl);
  if (!response.ok) {
    if (checkForXmlFile) {
      const xmlUrl = getXmlUrlForHymn(hymn);
      const response = await fetch(xmlUrl);
      if (!response.ok) {
        return brokenHymns; // song is not considered broken if we haven't yet uploaded a MusicXML file from SoundSlice
      }
    }
    const hymns = brokenHymns[orgId] ?? [];
    const brokenSongsForChurch = [...hymns, hymn];
    brokenHymns = {...brokenHymns, [orgId]: brokenSongsForChurch};
    setBrokenHymns(brokenHymns);
  }
  return brokenHymns;
}
