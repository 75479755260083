
export enum NetworkError {
  MidiDownloadFailed = 'midi-download-failed',
  MidiFileParseFailed = 'midi-file-parse-failed',  // technically not a network error...
  MP3DownloadFailed = 'mp3-download-failed',
  MP3GenerationFailed = 'mp3-generation-failed',
  TextDownloadFailed = 'text-download-failed',
  TextFileParseFailed = 'text-file-parse-failed',
  Unknown = 'unknown',
}
