import {Header} from '../header';
import {OuterPageContent} from '../../util/shared';
import {Footer} from '../footer';
import styled from 'styled-components/macro';
import {SingingPlanTile} from './singing_plan_tile';
import {ThisSundayTile} from './this_sunday_tile';
import {dateStringFromDate, getMonthName} from '../../../common/date_string';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {Pages} from '../../../common/pages';
import {getUrlForPage} from '../../util/path';
import {withSearchParams} from '../../util/use_location';
import {ActivateSearchQueryString} from '../onboarding/common';

export const TodayPage = () => {
  // TODO(hewitt): display Christian holiday name if appropriate (e.g. "Christmas Day 2024 AD", "Easter Sunday 2024 AD")
  const date = new Date();
  const title = `${getMonthName(dateStringFromDate(date))} ${date.getDate()}, ${date.getFullYear()} AD`;
  const navigate = useNavigate();

  const onSearchIconClick = useCallback(() => {
    navigate(withSearchParams(getUrlForPage(Pages.Organization), ActivateSearchQueryString));
  }, [navigate]);

  return (
    <OuterPageContent>
      <Header title={title} onSearchClick={onSearchIconClick}/>
      <TileBackground>
        <SingingPlanTile/>
        <ThisSundayTile/>
      </TileBackground>
      <Footer/>
    </OuterPageContent>
  );
}

const TileBackground = styled.div`
  font-size: min(max(4.5vw, 0.9em), 1.5em);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: auto;
  background-color: var(--color-background-search);
  text-align: left;
`;
