import '../util/shared.css';
import './hymns_list.css';
import React, {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  BackArrow,
  getHymnFromUrl,
  isHymnUrl,
  InnerPageContent,
  OuterPageContent
} from "../util/shared";
import {Header} from "./header";
import {isCustomSong, PlayerPage} from './player_page';
import {ReactComponent as CloseCircleIcon} from "../assets/close-circle.svg";
import {ReactComponent as PlayIcon} from "../assets/play.svg";
import {ReactComponent as SearchIcon} from "../assets/search.svg";
import {ReactComponent as PencilIcon} from "../assets/pencil.svg";
import {ReactComponent as TrashIcon} from "../assets/trash.svg";
import {Footer} from "./footer";
import {useLocalStorage} from "../data/use_local_storage";
import {LocalStorageKey} from "../data/client_local_storage";
import * as server_api from "../../common/server_api"
import {generateHymnUrl, getPageFromLocation, getUrlForPage} from "../util/path";
import {ensureExists, userVisibleSongNumber} from "../../common/util";
import {useLocation, useNavigate} from 'react-router-dom';
import {
  HouseholdStatus,
  Hymn,
  Hymnal,
  HymnIssue,
  OrganizationType,
  SongFeature,
  SongSlug,
} from "../../common/model";
import {ReactComponent as VocalsIcon} from "../assets/vocals.svg";
import styled from 'styled-components/macro';
import {useUserAttributes} from "../data/use_user_attributes";
import {synchronizeChurchWithServer, useChurch} from "../data/use_church";
import {OptInButton, SignUpButton} from '../util/sign_up_button';
import {isInsideMobileApp} from '../util/billing';
import {synchronizeHouseholdWithServer, useHousehold} from '../data/use_household';
import {maybeUpgradeApp} from '../util/app_upgrade';
import {Pages} from '../../common/pages';
import {activateSearch as onboardingActivateSearch} from './onboarding/common';
import {getPlatform, Platform} from '../util/platform';
import {Spinner} from '../util/spinner';
import {useHymnals} from '../data/use_hymnals';
import {HymnalWithHymns, PrimaryChurchHymnalId} from '../data/use_hymnals';
import useWindowDimensions from '../util/useWindowDimensions';
import {churchHymnalIconPath} from '../../common/paths';

export const LibraryPageTitle = 'Library';
const MaxSearchResultsPerHymnal = 50;
const WrapHymnNames = false;
export const HymnRowHeight = WrapHymnNames ? '2.3lh' : '1.7lh';

const hymnalAccordionThresholds: Array<{screenHeight: number, maxHeadingCount: number}> = [
  {screenHeight: 500, maxHeadingCount: 3},
  {screenHeight: 700, maxHeadingCount: 4},
  {screenHeight: 1000, maxHeadingCount: 5},
];

export interface Props {
  hymnals: HymnalWithHymns[];
  title: string;
  weeklySongGroups?: HymnalWithHymns[];
  message?: JSX.Element;
  isDemo?: boolean;
  showThisSundayOnly?: boolean;
  onEditHymnal?: (hymnal: HymnalWithHymns) => void;
  activateSearch?: boolean;
  onSongClick?: (songSlug: SongSlug | undefined) => void;
  onSearchKeyDownProp?: (event: KeyboardEvent<HTMLInputElement>, searchString?: string) => void;
  onPreviewHymn?: (hymn: Hymn | undefined) => void;
  onClose?: () => void;
  playButton?: () => React.ReactElement | null;
  defaultSearchString?: string;
  isInSongSelector?: boolean;
  selectSearchValue?: boolean;
  deleteButtonVisible?: boolean;
  isCurrentHymnalSticky?: boolean;
  isFirstHymnalFancy?: boolean;
  headerAfterFirstHymnal?: JSX.Element;
}

export const HymnsList = ({
  hymnals,
  title,
  weeklySongGroups,
  message,
  isDemo,
  showThisSundayOnly,
  onEditHymnal,
  activateSearch,
  onSongClick,
  onSearchKeyDownProp,
  onPreviewHymn,
  onClose,
  playButton,
  defaultSearchString,
  isInSongSelector,
  selectSearchValue,
  deleteButtonVisible,
  isCurrentHymnalSticky,
  isFirstHymnalFancy,
  headerAfterFirstHymnal,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(!!isInSongSelector);
  const [searchString, setSearchString] = useState(defaultSearchString ?? '');
  // TODO(hewitt): Eliminate this variable - only used to translate URL hymnal name to id
  //               Should be using React router to route to hymn, and should also be using song slug as URL
  const allHymnalsForUrlLookupOnly = useHymnals();
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [searchIsVisible, setSearchIsVisible] = useState(false);
  const [currentHymnalName, setCurrentHymnalName] = useLocalStorage(LocalStorageKey.CurrentHymnalName);
  const [currentHymnal, setCurrentHymnal] = useState<Hymnal | undefined>(
    isCurrentHymnalSticky ? hymnals.find(hymnal => hymnal.name === currentHymnalName) : undefined
  );
  const {isInternalUser, isChurchAdmin} = useUserAttributes();
  const {church} = useChurch();
  const {household} = useHousehold();
  const householdStatus = household?.status;
  const navigate = useNavigate();

  // state var for whether to display player or not, sort of like a boolean but is a Hymn obj
  // initialize to undefined
  const [playlist, setPlaylist] = useState<Hymn[] | undefined>(undefined);
  const location = useLocation();
  const page = getPageFromLocation(location);
  const {search} = location;
  const windowDimensions = useWindowDimensions();
  const [ignoreMouseHover, setIgnoreMouseHover] = useState(false);

  const shouldActivateSearch = onboardingActivateSearch() || activateSearch;

  const searchInputRef = useRef<HTMLInputElement>(null);
  // Note: This hymnals.length check allows Favorites to bypass MaxSearchResultsPerHymnal
  const singleHymnal = !weeklySongGroups && (currentHymnal !== undefined || hymnals.length === 1);
  const showingWeeklySongLists = weeklySongGroups && !singleHymnal;
  const hymnsListTitle = currentHymnal?.name ?? title;
  const disablePaidContent = householdStatus !== HouseholdStatus.Subscribed && !isInternalUser;
  const isSingleStaticList = weeklySongGroups?.length === 1 && weeklySongGroups[0].name === '';
  const maxSearchResultsPerHymnal = !singleHymnal && !isSingleStaticList
    ? MaxSearchResultsPerHymnal : Number.MAX_SAFE_INTEGER;

  const songClickIsEnabled = useCallback((hymnal?: Hymnal) =>
      Boolean(!disablePaidContent || (showingWeeklySongLists && !searchString) || hymnal?.id === PrimaryChurchHymnalId),
    [disablePaidContent, showingWeeklySongLists, searchString]
  );
  const [selectedSongIndex, setSelectedSongIndex] = useState<number>(0);

  const isFancyHymnalListVisible = useMemo(() => {
    const maybeFavorites = hymnals.length === 1; // TODO(hewitt): remove when we separate Hymnals from HymnsList
    return !weeklySongGroups && !searchString && !maybeFavorites && !currentHymnal;
  }, [currentHymnal, hymnals.length, searchString, weeklySongGroups]);

  useEffect(() => {
    if (activateSearch && selectSearchValue) {
      searchInputRef.current?.select();
    }
  }, [activateSearch, searchInputRef, selectSearchValue]);

  useEffect(() => {
    setTimeout(() => setLoading(false));
  }, []);

  useEffect(() => {
    void maybeUpgradeApp();
  }, []);

  useEffect(() => {
    if (!isCurrentHymnalSticky) {
      return;
    }
    setCurrentHymnalName(currentHymnal?.name);
  }, [currentHymnal, hymnals, isCurrentHymnalSticky, setCurrentHymnalName]);

  const searchRegEx = useMemo(() => {
    // if search string starts with nonAlphaNumeric character(s) chop them off
    // also remove all apostrophes (removed from filenames during search as well)
    const searchTrimmed = searchString
      .replace(/^[^a-z\d'’]+/gi, '')
      .replace(/['’]/g, '')
    const spaceRegEx = `[a-z0-9]*[^a-z0-9']+`
    const searchTerms = searchTrimmed.replaceAll(/[^a-z\d'’]+/gi, spaceRegEx)
    return new RegExp(searchTerms, 'gi')
  }, [searchString]);

  function renderSongDisplayName(hymn: Hymn) {
    const displayName = hymn.title;

    const match = displayName.match(searchRegEx)

    const startIndex = displayName.search(searchRegEx)
    const endIndex = startIndex + (match && match.length ? match[0].length : 0)

    const beforeBold = displayName.slice(0, startIndex)
    const bold = displayName.slice(startIndex, endIndex)
    const afterBold = displayName.slice(endIndex, displayName.length)

    return (
      <SongNameWrapper key={hymn.number}>
        <SongNameInnerText key='song-name-inner-text'>
          {beforeBold}
          <b>{bold}</b>
          {afterBold}
        </SongNameInnerText>
        <VocalsIconWrapper key='vocals-icon-wrapper' $visible={hymn.features?.includes(SongFeature.Vocals)}>
          <VocalsIcon key='vocals-icon' className="vocalsIcon"/>
        </VocalsIconWrapper>
      </SongNameWrapper>
    )
  }

  const getVisibleHymns = useCallback((hymnal: HymnalWithHymns) => {
    if (!searchString) {
      return hymnal.hymns;
    }
    return hymnal.hymns
      .filter((hymn) => {
        const number = userVisibleSongNumber(hymn.number);
        const psalmSuffix = hymn.psalm && !ensureExists(hymn.title).toLowerCase().includes('psalm') ? ' - ' + hymn.psalm : '';
        const hymnString = number + ' - ' + hymn.title.replace(/['’]/g, '') + psalmSuffix;
        return hymnString.search(searchRegEx) !== -1;
      })
  }, [searchRegEx, searchString]);

  // Hacky performance optimization.  Instead of these variables being useState instances updated by a useEffect,
  // we recalculate them on every render because the performance is better (go figure).  Ultimately, we need
  // to break down the monolithic HymnsList component into reusable subcomponents, which will enable us to
  // better reason about the interdependencies.  Note that when these variables were useState instances, opening
  // a hymnal became very slow, and the big hint was that the church hymnal rendered for a moment before rendering
  // the newly selected hymnal.  This fact could guide us to a better solution in the future.
  const {visibleHymnals, displayHymnalTitle, songList} = (() => {
    const tempList: Hymn[] = [];

    let preFilteredHymnals: HymnalWithHymns[];
    let displayHymnalTitle = true;

    if (!searchString && showingWeeklySongLists && weeklySongGroups) {
      preFilteredHymnals = weeklySongGroups.map(hymnal => {
        if (hymnal.name !== '') {
          return hymnal;
        }
        const hymns = hymnals.find(({id}) => id === PrimaryChurchHymnalId)?.hymns ?? [];
        return {...hymnal, hymns};
      });
    } else if (currentHymnal !== undefined) {
      preFilteredHymnals = hymnals.filter(hymnal => hymnal.id === currentHymnal?.id);
      displayHymnalTitle = false;
    } else {
      preFilteredHymnals = hymnals;
      displayHymnalTitle = !(hymnals.length === 1 && hymnals[0].hideTitle);
    }

    const filteredHymnals = preFilteredHymnals
      .filter(hymnal => isInternalUser || !hymnal.isInternal)
      .map(hymnal => ({...hymnal, hymns: getVisibleHymns(hymnal)}))
      .map(hymnal => ({...hymnal, message: hymnal.hymns.length === 0 ? hymnal.message : undefined}))
      .filter(hymnal =>
        hymnal.hymns.length > 0 || hymnal.message || (hymnal.id === PrimaryChurchHymnalId && !searchString));

    const hymnalsToRender = filteredHymnals;

    for (const hymnal of hymnalsToRender) {
      for (const hymn of getVisibleHymns(hymnal)) {
        tempList.push(hymn);
      }
    }
    return {visibleHymnals: hymnalsToRender, displayHymnalTitle, songList: tempList};
  })();

  useEffect(() => {
    setSelectedSongIndex(0);
  }, [songList.length]);

  useEffect(() => {
    onPreviewHymn?.(isFancyHymnalListVisible ? undefined : songList[selectedSongIndex]);
  }, [isFancyHymnalListVisible, isInSongSelector, onPreviewHymn, searchString, selectedSongIndex, songList]);

  const suppressSongIssue = useCallback((hymnal: HymnalWithHymns, hymn: Hymn) => {
    const {issue} = hymn;
    return hymnal.id === PrimaryChurchHymnalId || (
      !searchString &&
      !singleHymnal &&
      Boolean(weeklySongGroups || (isInSongSelector && issue !== HymnIssue.Missing))
    )
  }, [searchString, singleHymnal, weeklySongGroups, isInSongSelector]);

  const handleArrowKey = useCallback((arrow: string) => {
    const newIndex = Math.max(0, Math.min(songList.length - 1, selectedSongIndex + (arrow === 'ArrowDown' ? 1 : -1)));
    setSelectedSongIndex(newIndex);
    setIgnoreMouseHover(true); // prevent mouse from contending with keyboard for selection
    setTimeout(() => setIgnoreMouseHover(false)); // allow mouse to take over after keyboard completes
  }, [selectedSongIndex, songList.length]);

  const onKeyUp = useCallback((event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        const hymn = songList[selectedSongIndex];
        if (onSongClick) {
          onSongClick(hymn.slug);
        } else {
          const hymnal = hymnals.find(({name}) => name === hymn.hymnal);
          if (!hymnal) {
            return;
          }
          const hymnUrl = generateHymnUrl({
            hymn,
            page: isDemo || showThisSundayOnly ? Pages.Library : page,
            suppressSongIssue: suppressSongIssue(hymnal, hymn),
          });
          navigate(hymnUrl);
        }
        break;
      }
      case 'ArrowDown':
        handleArrowKey(event.key);
        break;
      case 'ArrowUp':
        handleArrowKey(event.key);
        break;
    }
  }, [
    handleArrowKey, onSongClick, selectedSongIndex, songList,
    hymnals, isDemo, navigate, page, showThisSundayOnly, suppressSongIssue
  ]);

  function hymnalNameShouldBeWrapped(hymnalName: string) {
    // only wrap if the time or location is specified
    return hymnalName.includes('@');
  }

  const maxHeadingCount: number = useMemo(() => {
    for (const {screenHeight, maxHeadingCount} of hymnalAccordionThresholds) {
      if (windowDimensions.height <= screenHeight) {
        return maxHeadingCount;
      }
    }
    return hymnalAccordionThresholds[hymnalAccordionThresholds.length - 1].maxHeadingCount;
  }, [windowDimensions.height]);


  function renderHymnalHeadingAndHymns({
    headingIndex,
    headingCount,
    firstChildIndex,
    hymnal,
    displayHymnalTitle,
    lastHymnalNameIsWrapped,
  }: {
    headingIndex: number;
    headingCount: number;
    firstChildIndex: number;
    hymnal: HymnalWithHymns;
    displayHymnalTitle: boolean;
    lastHymnalNameIsWrapped: boolean;
  }): (ReactElement | null)[] {
    const visibleHymns = getVisibleHymns(hymnal);
    const nthChild = firstChildIndex + 1;

    function scrollIntoView() {
      // Because headings (This Week, Last Week) are sticky, if we scroll them into view, the scroll calculation
      // will be based on where the header is stuck rather than where it currently resides in the scrolled list,
      // causing it not to scroll into the correct location.
      // For example, say the header is stuck to the bottom, but its current position is actually off the bottom
      // of the screen.  When we scroll it into view, the scroll calculation will determine the distance from its
      // stuck location to the desired scroll location and scroll by that amount.  This will not scroll far enough.
      // Instead, it should have calculated based on the distance from where it currently resides in the scrolled
      // list, which is off the bottom of the screen.
      // So, we scroll based on the first child of the header, which is not sticky, and therefore always scrolls to the
      // correct location on the screen, causing the header to also be positioned correctly.
      // Both Safari & Chrome exhibit the same behavior - not sure about other browsers, but don't really care.
      const firstHymnId = (nthChild + 1).toString() + '-hymn';
      document.getElementById(firstHymnId)?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    }

    function wrapHymnalName(fullHymnalName: string): ReactElement {
      const hymnalName = (
        <HymnalNameWithEditButton key={fullHymnalName + 'hymnal-name-with-edit-button'}>
          <HymnalName key={fullHymnalName + 'hymnal-name'} $disabled={!songClickIsEnabled(hymnal)}>
            {hymnalNameShouldBeWrapped(fullHymnalName) ? fullHymnalName.split('-')[0] : fullHymnalName}
          </HymnalName>
          {
            onEditHymnal && (isChurchAdmin || isInternalUser) && !showThisSundayOnly &&
            <EditHymnalButton key={'edit-' + fullHymnalName} onClick={onEditHymnal} hymnal={hymnal}/>
          }
        </HymnalNameWithEditButton>
      );
      let hymnalNameParts: ReactElement[];
      if (!hymnalNameShouldBeWrapped(fullHymnalName)) {
        hymnalNameParts = [hymnalName];
      } else {
        hymnalNameParts = [
          hymnalName,
          <HymnalNameSecondLine key={fullHymnalName + '-hymnalNameSecondLine'}>
            {fullHymnalName.split('-')[1].trim()}
          </HymnalNameSecondLine>
        ];
      }
      return (
        <HymnalNameRow key={fullHymnalName + '-hymnal-name-row'}>
          <HymnalNameGroup key={fullHymnalName + '-hymnal-name-group'}>
            {hymnalNameParts}
          </HymnalNameGroup>
        </HymnalNameRow>
      );
    }

    const tableRows = hymnal.message
      ? [
        <HymnalMessageWrapper key={hymnal.name + '-message'}>
          <HymnalMessage key={'hymnal-message'}>{hymnal.message}</HymnalMessage>
        </HymnalMessageWrapper>
      ] : Array.from(visibleHymns.slice(0, maxSearchResultsPerHymnal + 1).entries())
      .map(([index, hymn]) => {
        const hymnUrl = generateHymnUrl({
          hymn,
          page: isDemo || showThisSundayOnly ? Pages.Library : page,
          suppressSongIssue: suppressSongIssue(hymnal, hymn),
        });

        const thisRowNthChild = nthChild + index + 1;
        const hymnId = thisRowNthChild.toString() + '-hymn';

        function onMouseOver() {
          if (!isInSongSelector || ignoreMouseHover) {
            return;
          }
          const absoluteIndex = songList.indexOf(hymn);
          if (absoluteIndex === -1) {
            return;
          }
          setSelectedSongIndex(absoluteIndex);
        }

        if (index === maxSearchResultsPerHymnal) {
          return (
            <HymnRow
              key={hymnal.name + '-ellipsis'}
              $isDisabled={false}
              $isSelected={false}
              $roundedCorners={!!isInSongSelector}
            >
              <EllipsisCell key={'ellipsis'}>...</EllipsisCell>
            </HymnRow>
          );
        }

        function navigateToSong() {
          if (!songClickIsEnabled(hymnal)) {
            return;
          }
          if (onSongClick) {
            return onSongClick(hymn.slug);
          }
          navigate(hymnUrl);
        }

        const numberCell = (
          <SongNumber key={hymn.number + '-songNumber'}>
            {isCustomSong(hymn) ? '' : userVisibleSongNumber(hymn.number)}
          </SongNumber>
        )

        const titleCell = (
          <SongName key={hymn.number + 'songName'}>
            {renderSongDisplayName(hymn)}
          </SongName>
        )

        const psalmText =
          hymn.psalm &&
          // TODO(hewitt): the 'ps' check is because cc2020 697 title "Abide with Me - Fast Falls the Eventide"
          //               contains a dash, causing the psalm to be set to 'Fast Falls the Eventide',
          //               and it is unclear where this happens.  The hymns list code needs to be rewritten,
          //               at which time this will become clear.
          hymn.psalm.toLowerCase().startsWith('ps') &&
          hymn.psalm.substring(0, 2) + '.' + hymn.psalm.substring(5);


        const isSelected = getPlatform() !== Platform.Mobile && searchIsVisible &&
          selectedSongIndex !== undefined && songList[selectedSongIndex] === hymn;

        const charCount = psalmText ? psalmText.length : 0;
        const psalmCell = (
          <div key={hymn.number + '-psalm'} style={{display: 'flex', alignItems: 'center'}}>
            <Psalm key='psalm' $charCount={charCount}>
              {psalmText}
            </Psalm>
          </div>
        )

        const key = hymnal.name + ' ' + hymn.number + ' ' + hymn.title + ' ' + thisRowNthChild.toString();
        const isDisabled = !suppressSongIssue(hymnal, hymn) && (
          (hymn.issue && hymn.issue !== HymnIssue.Text) ||
          !songClickIsEnabled(hymnal)
        );

        const playButtonCell = playButton && isSelected && playButton?.();

        const content = [
          numberCell,
          titleCell,
          hymn.psalm ? psalmCell : null,
          playButtonCell,
        ];

        // It is very expensive to utilize a custom styled component for every row in the hymnal when only the
        // first hymn after the heading is used for scrolling.  Thus, we only use a non-custom styled component (below)
        // for all the rest of the hymns.
        if (index === 0) {
          return (
            <ScrollableHymnRow
              key={key}
              id={hymnId}
              onClick={navigateToSong}
              onMouseOver={onMouseOver}
              $headingIndex={headingIndex}
              $nthChild={thisRowNthChild}
              $isDisabled={isDisabled}
              $isSelected={isSelected}
              $roundedCorners={!!isInSongSelector}
            >
              {content}
            </ScrollableHymnRow>
          )
        }
        return (
          <HymnRow
            key={key}
            id={hymnId}
            onClick={navigateToSong}
            onMouseOver={onMouseOver}
            $isDisabled={isDisabled}
            $isSelected={isSelected}
            $roundedCorners={!!isInSongSelector}
          >
            {content}
          </HymnRow>
        )
      });

    let hymnalHeading = null;
    if (displayHymnalTitle) {
      hymnalHeading = headingCount > maxHeadingCount || church?.type === OrganizationType.School ? (
        <HymnalHeading
          key={hymnal.name + '-heading'}
          onClick={scrollIntoView}
        >
          {wrapHymnalName(hymnal.name)}
        </HymnalHeading>
      ) : (
        <HymnalHeadingWithAccordion
          key={hymnal.name + '-heading'}
          $headingIndex={headingIndex}
          $headingCount={headingCount}
          $nthChild={nthChild}
          $lastHymnalNameIsWrapped={lastHymnalNameIsWrapped}
          onClick={scrollIntoView}
        >
          {wrapHymnalName(hymnal.name)}
        </HymnalHeadingWithAccordion>
      );
    }

    return [hymnalHeading, ...tableRows];
  }

  const cancelSearch = useCallback(() => {
    setSearchString('');
    setSearchIsVisible(false);

    if (shouldActivateSearch) {
      if (onClose) {
        onClose();
      } else {
        navigate(getUrlForPage(Pages.Today));
      }
    }
  }, [shouldActivateSearch, navigate, onClose]);

  const onSearchKeyUp = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      cancelSearch();
      searchInputRef.current?.blur();
    } else if (event.key === 'Enter' && !searchString) {
      onSongClick?.(undefined); // clear song
    }
  }, [cancelSearch, onSongClick, searchString]);

  const onDeleteButtonClick = useCallback(() => {
    onSongClick?.(undefined);
    onClose?.();
  }, [onClose, onSongClick]);

  const onSearchFocus = useCallback(() => {
    setTimeout(() => setSearchHasFocus(true));
  }, [setSearchHasFocus]);

  const onSearchBlur = useCallback(() => {
    if (searchIsVisible) {
      searchInputRef.current?.focus(); // search retains focus so long as it is visible
    } else {
      setTimeout(() => setSearchHasFocus(false));
    }
  }, [searchIsVisible, searchInputRef, setSearchHasFocus]);

  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (loading) {
      return;
    }
    setSearchString(event.target.value);
  }, [loading]); // suppress DOM warning

  const onSearchKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key.length === 1 && loading) {
      setSearchString(searchString + event.key); // avoid dropping keystrokes while we are loading
    } else {
      onSearchKeyDownProp?.(event, searchString);
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }, [loading, onSearchKeyDownProp, searchString]);

  function renderSearchControl() {
    // TODO(hewitt): Resurrect song play through functionality
    const showPlayAllButton = false // !isFancyHymnalListVisible && songClickIsEnabled();
    const widthClassName = showPlayAllButton ? 'searchPartialWidth' : 'searchFullWidth';
    return (
      <OuterSearchWrapper key='search' id="subHeader" $visible={searchIsVisible} $fullWidth={!onClose}>
        {deleteButtonVisible && <DeleteButton onClick={onDeleteButtonClick}/>}
        {isInSongSelector && currentHymnal && <SearchBackArrow onClick={onHymnalBack}/>}
        <InnerSearchWrapper className={widthClassName} $fullWidth={!onClose}>
          <SearchIconCell key='searchIcon'>
            <SearchIconWrapper>
              <SearchIcon/>
            </SearchIconWrapper>
          </SearchIconCell>
          <SearchCell
            key='search'
            id='song-search-bar'
            type='text'
            placeholder='Search'
            onFocus={onSearchFocus}
            onBlur={onSearchBlur}
            onKeyUp={onSearchKeyUp}
            onKeyDown={onSearchKeyDown}
            autoComplete='off'
            className='searchInput'
            value={searchString}
            ref={searchInputRef}
            onChange={onSearchChange}
          />
          <SearchCancel key='cancelSearch' onClick={cancelSearch}>
            <CloseCircleIcon className='cancelSearchIcon'/>
          </SearchCancel>
        </InnerSearchWrapper>
        <PlayAllButton $visible={showPlayAllButton}>
          <PlayIcon onClick={loadList}/>
        </PlayAllButton>
      </OuterSearchWrapper>
    );
  }

  const scrollableDiv = useRef<HTMLDivElement>(null);

  function resetScrollPosition() {
    if (scrollableDiv.current) {
      scrollableDiv.current.scrollTop = 0;
    }
  }

  function renderFancyHymnalList() {
    return (
      <div>
        <table key="fancyHymnalList" className="hymnalList">
          <tbody key="fancyHymnalListBody">
          {
            visibleHymnals.map((hymnal, index) => renderFancyHymnal(hymnal, index))
          }
          </tbody>
        </table>
      </div>
    );
  }

  function renderFancyHymnal(hymnal: HymnalWithHymns, index: number, {singleton}: { singleton?: boolean } = {}) {
    const {name} = hymnal;
    return (
      <Fragment key={name}>
        <tr key={name} className="hymnal" onClick={() => {
          resetScrollPosition();
          setCurrentHymnal(hymnal);
        }}>
          <HymnalIconContainer key="icon">
            <img
              className={'hymnalIcon' + (hymnal.icon?.hasBorder ? ' hymnalIconBorder' : '')}
              src={hymnal.icon?.path ?? churchHymnalIconPath}
              alt="hymnal icon"
            />
          </HymnalIconContainer>
          <td key="description" className="hymnalDescription">
            <HymnalTitle style={{
              color: !songClickIsEnabled(hymnal) ? 'var(--color-text-light)' : 'var(--color-text)',
              ...(singleton && {
                fontFamily: 'Jost-SemiBold, Arial, sans-serif',
                fontSize: '1.2em',
              }),
            }}>
              {name}
            </HymnalTitle>
            <div key="publisher" className="hymnalPublisher">{hymnal.publisher}</div>
          </td>
        </tr>
        {
          headerAfterFirstHymnal && index === 0
            ? <tr><td>{headerAfterFirstHymnal}</td></tr>
            : (
              singleton || index === visibleHymnals.length - 1
                ? null
                : <tr key={name + '-divider'}>
                    <td key="empty"/>
                    <td key="divider">
                      <hr className="hymnalDivider"/>
                    </td>
                  </tr>
            )
        }
      </Fragment>
    );
  }

  function renderHymnals() {
    if (isFancyHymnalListVisible) {
      return renderFancyHymnalList();
    }

    let childCounter = 0;
    const lastHymnalNameIsWrapped = visibleHymnals.length > 0 &&
      hymnalNameShouldBeWrapped(visibleHymnals[visibleHymnals.length - 1].name);

    const firstHymnal = visibleHymnals[0];
    const fancyFirstHymnal =
      isFirstHymnalFancy &&
      !searchString &&
      !showThisSundayOnly &&
      !currentHymnal && (
        <table key='church-hymnal'>
          <tbody>{renderFancyHymnal(firstHymnal, 0, {singleton: true})}</tbody>
        </table>
      );

    return (
      <Fragment key='hymnals'>
        {fancyFirstHymnal}
        <HymnsListWrapper key='this-week-table'>
          {
            Array.from(visibleHymnals
              .filter(hymnal => !fancyFirstHymnal || hymnal !== firstHymnal)
              .entries()).map(([headingIndex, hymnal]) => {
                const result = renderHymnalHeadingAndHymns({
                  headingIndex,
                  headingCount: visibleHymnals.length,
                  firstChildIndex: childCounter,
                  hymnal,
                  displayHymnalTitle,
                  lastHymnalNameIsWrapped,
                });
                const headerCount = displayHymnalTitle ? 1 : 0;
                const ellipsisCount = 1;
                const messageCount = 1;
                childCounter += hymnal.hymns.length > 0
                  ? Math.min(hymnal.hymns.length + headerCount, maxSearchResultsPerHymnal + ellipsisCount + headerCount)
                  : messageCount + headerCount;
                return result;
            })
          }
        </HymnsListWrapper>
        {!visibleHymnals?.length && !searchHasFocus && !searchString && !weeklySongGroups && <Spinner key='spinner'/>}
      </Fragment>
    );
  }

  const onBack = useCallback(() => {
    setPlaylist(undefined);
    if (isHymnUrl(search)) {
      window.history.back();
    }
  }, [setPlaylist, search]);

  const onSearchIconClick = useCallback(() => {
    setSearchIsVisible(true);
    searchInputRef.current?.focus();
  }, [searchInputRef]);

  useEffect(() => {
    if (shouldActivateSearch) {
      onSearchIconClick();
    }
  }, [shouldActivateSearch, onSearchIconClick]);

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
      onSearchIconClick();
      event.preventDefault();
    }
  }, [onSearchIconClick]);

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('keydown', onKeyDown);
    return () => {
      // @ts-ignore
      document.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown]);

  //if there are search parameters, set state to search param hymn
  //meaning on refresh it immediately navigates back to song you were just on
  //using the contents of the browser search bar

  useEffect(() => {
    const hymn = getHymnFromUrl(allHymnalsForUrlLookupOnly, search);
    if (!hymn) {
      return;
    }
    setPlaylist(hymn);
  }, [allHymnalsForUrlLookupOnly, search]);

  const loadList = useCallback(() => {
    if (!songClickIsEnabled()) {
      return;
    }
    if (songList.length > 0) {
      setPlaylist(songList)
    }
  }, [songClickIsEnabled, songList]);

  // When the browser navigates back to the song list from a single song,
  // the search string is cleared, but the playlist still contains a single song.
  // If the playlist contains multiple songs, it is not possible to navigate
  // back in the browser because we do not navigate to a new URL.
  // This is because the single song attributes are passed through the URL ,
  // but multiple songs are passed to the PlayerPage via the "playlist" prop
  const navigatedBackToHymnsList = playlist &&
    playlist.length === 1 && !new URLSearchParams(search).get('title');

  if (playlist && !navigatedBackToHymnsList) {
    return (
      <PlayerPage hymns={playlist} onBack={onBack}/>
    );
  }

  const searchInputElement = document.getElementById('song-search-bar')
  const hymnsTableElement = document.getElementById('song-list')
  hymnsTableElement?.addEventListener("touchmove", () => searchInputElement?.blur())
  const onHymnalBack = singleHymnal ? () => {
    resetScrollPosition();
    setCurrentHymnal(undefined);
  } : undefined;
  // hide footer in demo mode or if on-screen keyboard visible
  const hideFooter =
    isDemo ||
    showThisSundayOnly ||
    (searchHasFocus && isInsideMobileApp()) ||
    onSongClick;
  const isUploadSongButtonVisible = Boolean(
    church &&
    singleHymnal &&
    currentHymnal?.id === PrimaryChurchHymnalId &&
    (isChurchAdmin || isInternalUser));

  return (
    <OuterPageContent key='outer' $noHeight={showThisSundayOnly} onKeyUp={onKeyUp}>
      {searchIsVisible || showThisSundayOnly ? undefined : (
        <>
          <Header
            key='header'
            title={hymnsListTitle}
            onBack={onHymnalBack}
            isUploadSongButtonVisible={isUploadSongButtonVisible}
            suppressIcons={isDemo}
            onSearchClick={onSearchIconClick}
          />
        </>
      )}
      {!isDemo && !showThisSundayOnly && renderSearchControl()}
      {
        !songClickIsEnabled() &&
        currentHymnal?.id !== PrimaryChurchHymnalId &&
        hymnals.length > 0 &&
        !searchHasFocus &&
        <SubscriptionInvitation key='subscription-invitation'/>
      }
      <InnerPageContent key='inner' id="song-list" ref={scrollableDiv}>
        {
          (!message || searchString || searchHasFocus) && (hymnals.length > 0 ? renderHymnals() : <Spinner/>)
        }
        {
          (message && !searchHasFocus && searchString === '') ? (
            <div key='message' className='message'>
              {message}
            </div>
          ) : null
        }
      </InnerPageContent>
      {hideFooter ? undefined : (
        <Footer key='footer'/>)}
    </OuterPageContent>
  );
}

const VocalsIconWrapper = styled.div<{$visible?: boolean;}>`
  display: ${props => props.$visible ? 'inherit' : 'none'};
  flex-shrink: 0;
`;

function SubscriptionInvitation() {
  const {church} = useChurch();
  const {household} = useHousehold();
  const householdToken = household?.token;
  const displayOptIn = householdToken && church?.token && church?.areHouseholdsSubscribed;
  const message = displayOptIn
    ? 'Your church provides full subscriptions to all members.'
    : 'Subscribe your household to play all music.';
  const onOptIn = useCallback(async () => {
    await server_api.optHouseholdIntoChurchSubscription({
      householdToken: ensureExists(householdToken),
      churchToken: ensureExists(church?.token),
    });
    await synchronizeHouseholdWithServer({force: true});
    await synchronizeChurchWithServer({force: true});
  }, [householdToken, church]);
  if (displayOptIn) {
    return (
      <OptInContainer>
        <OptInMessage>{message}</OptInMessage>
        <OptInButton onClick={onOptIn}/>
      </OptInContainer>
    )
  }
  return (
    <SubscribeContainer>
      <SubscribeMessage>{message}</SubscribeMessage>
      <SignUpButton subscribeButton={household?.status === HouseholdStatus.Unsubscribed}/>
    </SubscribeContainer>
  )
}

const OptInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  gap: 10px;
  font-size: 1.3em;
  text-wrap: wrap;
`;

const SubscribeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding: 10px 3%;
`;

const SubscribeMessage = styled.div`
`;

const OptInMessage = styled.div`
  max-width: 21ch;
`;

const HymnalHeading = styled.div`
  z-index: 1;
  font-family: Jost-SemiBold, "Arial", sans-serif;
  font-size: 1.5em;
  text-align: left;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  cursor: pointer;
  padding-left: 10px;
`;

const HymnalHeadingWithAccordion = styled(HymnalHeading)<{
  $headingIndex: number;
  $headingCount: number;
  $nthChild: number;
  $lastHymnalNameIsWrapped: boolean;
}>`
  &:nth-child(${props => props.$nthChild}) {
      top: ${props => props.$headingIndex * 1.75}em;
      bottom: ${props => (props.$headingCount - props.$headingIndex - 1) * 1.75 + (
        props.$lastHymnalNameIsWrapped && props.$headingIndex !== (props.$headingCount - 1) ? 1.5 : 0
      )}em;
  }
`;

const HymnalNameRow = styled.div`
  padding-top: 0.5em;
`;

const HymnalNameGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

// for weekly song lists
const HymnalName = styled.div<{$disabled: boolean}>`
  color: ${props => props.$disabled ? 'var(--color-text-light)' : 'var(--color-text)'};
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// for fancy hymnal list
const HymnalTitle = styled.div`
  white-space: normal;
`;

const HymnalNameSecondLine = styled.div`
  font-family: Jost-Regular, 'Arial', sans-serif;
  font-size: 0.7em;
  padding-bottom: 0.5em;
`;

const HymnalMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const HymnalMessage = styled.div`
  white-space: normal;
  text-align: center;
  max-width: 28ch;
`;

const HymnRow = styled.div<{
  $isDisabled: boolean;
  $isSelected: boolean;
  $roundedCorners: boolean;
}>`
  display: flex;
  position: relative;
  ${WrapHymnNames ? `
    align-items: top;
  ` : `
    align-items: center;
  `}
  height: ${HymnRowHeight};
  white-space: normal;
  cursor: pointer;
  ${props => props.$roundedCorners ? 'border-radius: 10px;' : ''}
  ${props => props.$isDisabled ? `
      color: var(--color-text-light);
      fill: var(--color-text-light); 
    ` : `
      color: var(--color-text);
      fill: var(--color-text); 
    `
  };
  ${props => props.$isSelected ? `
      background: var(--color-background-selected-item);
    ` : `
      background: var(--color-background); 
    `
  };
`;

const EllipsisCell = styled.div`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  letter-spacing: 3px;
`;

const ScrollableHymnRow = styled(HymnRow)<{
  $headingIndex: number;
  $nthChild: number;
}>`
  &:nth-child(${props => props.$nthChild}) {
    // TODO(hewitt): This calculation is not quite right, but close enough for now.
    //               The idea is that the first hymn following a header should scroll up to just below the header.
    scroll-margin-top: ${props => (props.$headingIndex + 2) * 2.6 - 1.6}em;
  }
`;

const SongNumber = styled.div`
  width: 4ch;
  text-align: right;
  text-wrap: nowrap;
  // font-family: Jost-SemiBold, Arial, sans-serif;
  // font-size: 1.5rem;
`;

const SongName = styled.div`
  flex: 1;
  padding-left: 7px;
  white-space: normal;
  overflow: hidden;
`;

const SongNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2px;
`;

const SongNameInnerText = styled.div`
  ${WrapHymnNames ? `
    text-wrap: wrap;

    // Limit wrap to two lines
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    line-clamp: 2;
    -webkit-box-orient: vertical;
  ` : `
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const Psalm = styled.div<{$charCount: number}>`
  text-align: right;
  width: ${props => props.$charCount}ch;
  ${props => props.$charCount === 0 ? '' :`
    padding-right: 5px; 
  `};
  text-wrap: nowrap;
`;

const PlayAllButton = styled.div<{$visible?: boolean;}>`
  display: ${props => props.$visible ? 'inherit' : 'none'};
  margin: auto;
  padding-right: 5px;
  padding-top: 3px;
  width: 30px;
  height: 30px; // necessary on Safari (go figure...)
  cursor: pointer;
  fill: var(--color-text);
`;

function EditHymnalButton({hymnal, onClick}: {hymnal: HymnalWithHymns, onClick: (hymnal: HymnalWithHymns) => void}) {
  return (
    <Pencil onClick={() => onClick(hymnal)} />
  );
}

const Pencil = styled(PencilIcon)`
  width: 30px;
  padding-left: 10px;
  cursor: pointer;
  fill: var(--color-text);
`;

const HymnalNameWithEditButton = styled.div`
  display: flex;
`;

const OuterSearchWrapper = styled.div<{$visible: boolean, $fullWidth: boolean}>`
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 7px;
  text-align: center;
  width: ${props => !props.$visible ? '0' :
    (props.$fullWidth ? '100vw' : 'auto')
  };
  overflow: ${props => props.$visible ? 'revert' : 'hidden'};
  position: ${props => props.$visible ? 'revert' : 'absolute'};
`;

const InnerSearchWrapper = styled.div<{$fullWidth: boolean}>`
  display: flex;
  align-items: center;
  background-color: var(--color-background-search);
  border-radius: 20px;
  box-sizing: border-box;
  height: 40px;
  margin-left: ${props => props.$fullWidth ? '15px' : '0'};
  margin-right: ${props => props.$fullWidth ? '10px' : '0'};
`;

const SearchIconCell = styled.div`
  width: 30px;
  margin: 0 4px;
`;

const SearchIconWrapper = styled.div`
  width: 25px;
  padding-left: 8px;
  padding-top: 1px;
  fill: var(--color-text-search-placeholder);
`;

const SearchCell = styled.input`
`;

const SearchCancel = styled.div`
  padding-left: 8px;
  padding-right: 3px;
`;

const DeleteButton = styled(TrashIcon)`
  margin-right: 5px;
  fill: var(--color-trash);
  background-color: var(--color-background);
  padding: 4px;
  border-radius: 10px;
  width: 40px;
  height: 35px;
  cursor: pointer;
`;

const HymnsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  border-spacing: 0;
  position: relative;
`;

const SearchBackArrow = styled(BackArrow)`
  padding-right: 10px;
`;

export const HymnalEntryPadding = '0.3rem';

const HymnalIconContainer = styled.td`
  width: 4.7em;
  padding: ${HymnalEntryPadding};
`;
