import {dateFromString, DateString, dateStringFromDate, getMonthName} from '../../../../common/date_string';
import {
  BoundSongListEntry,
  isBound,
  SongList,
  SongListEntry,
  SongListType,
  SongSlug,
  UnboundSongListEntry
} from '../../../../common/model';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {SongSelector, SongSelectorProps} from './song_selector';
import {getHymnFromSlug} from '../../../util/path';
import {userVisibleSongNumber} from '../../../../common/util';
import styled from 'styled-components/macro';
import {ColumnFullWidth, LiturgyYearHeight, MaxSongListRowCount, SongRowHeight, SongRowWidth} from './shared';
import {useAllSongLists} from './use_all_song_lists';
import {Device, getDevice, getPlatform, Platform} from '../../../util/platform';
import {CellPosition} from './liturgy_planner';
import {HymnOfTheMonthPlanner, HymnOfTheMonthPlannerProps} from './hymn_of_the_month_planner';
import {CustomMusic} from '../../../data/use_custom_music';
import {HymnalWithHymns} from '../../../data/use_hymnals';

const CellHoverOutlineWidth = '3px';

interface SongCellProps {
  column: number;
  customMusic?: CustomMusic;
  date: DateString;
  gridRowOffset?: number;
  hymnals?: HymnalWithHymns[];
  row: number;
  songList: SongList;
  songListType: SongListType;
  selectedCell?: CellPosition;
  setSelectedCell?: (cellPosition: CellPosition) => void;
  minDate?: DateString;
  maxDate?: DateString;
  isInHymnOfTheMonthPlanner?: Boolean;
}

export const SongCell = ({
  column,
  customMusic,
  date,
  gridRowOffset,
  hymnals,
  row,
  songList,
  songListType,
  selectedCell,
  setSelectedCell,
  minDate,
  maxDate,
  isInHymnOfTheMonthPlanner
}: SongCellProps) => {
  const [songSelectorProps, setSongSelectorProps] = useState<SongSelectorProps | undefined>();
  const [hymnOfTheMonthPlannerProps, setHymnOfTheMonthPlannerProps] = useState<HymnOfTheMonthPlannerProps | undefined>();
  const song = songList.songs.find(song => song.row === row);
  const allSongLists = useAllSongLists();
  const editSongCellRef = useRef<HTMLDivElement>(null);
  let tabIndexCount = 0;

  const onCloseSongSelector = useCallback(() => {
    setSongSelectorProps(undefined);
  }, []);

  const onCloseHymnOfTheMonthPlanner = useCallback(() => {
    setHymnOfTheMonthPlannerProps(undefined);
  }, []);

  const bindSongListEntry = useCallback((slug: string) => {
    let entry: BoundSongListEntry;
    if (song) {
      const {text, ...songNoText} = song as UnboundSongListEntry; // strip 'text' to replace with 'slug'
      entry = {...songNoText, slug};
    } else {
      entry = {row, slug};
    }
    songList.songs = [...songList.songs.filter(song => song.row !== row), entry]
      .sort((lhs, rhs) => lhs.row - rhs.row);
    if (songList.id) {
      void allSongLists.update(songList);
    } else {
      void allSongLists.add(songList);
    }
  }, [allSongLists, row, song, songList]);

  const deleteSongListEntry = useCallback(() => {
    const songs = songList.songs.filter(song => song.row !== row);
    void allSongLists.update({...songList, songs});
  }, [allSongLists, row, songList]);

  const moveDown = useCallback(() => {
    if (selectedCell && setSelectedCell) {
      if (selectedCell.row === MaxSongListRowCount) {
        setSelectedCell({date: date, row: 1});
        return;
      }
      setSelectedCell({date: selectedCell.date, row: selectedCell.row + 1});
    }
  }, [date, selectedCell, setSelectedCell]);

  const moveUp = useCallback(() => {
    if (selectedCell && setSelectedCell) {
      if (selectedCell.row === 1) {
        setSelectedCell({date: date, row: MaxSongListRowCount});
        return;
      }
      setSelectedCell({date: selectedCell.date, row: selectedCell.row - 1});
    }
  }, [date, selectedCell, setSelectedCell]);

  const moveLeft = useCallback(() => {
    if (selectedCell && setSelectedCell && minDate) {

      if (selectedCell.date !== minDate) {
        const selectedCellDate = dateFromString(selectedCell.date);
        const newDate = new Date(selectedCellDate.getFullYear(), selectedCellDate.getMonth(), selectedCellDate.getDate() - 7);
        setSelectedCell({date: dateStringFromDate(newDate), row: selectedCell.row});
      }
    }
  }, [minDate, selectedCell, setSelectedCell]);

  const moveRight = useCallback(() => {
    if (selectedCell && setSelectedCell && maxDate) {
      if (selectedCell.date !== maxDate) {
        const selectedCellDate = dateFromString(selectedCell.date);
        const newDate = new Date(selectedCellDate.getFullYear(), selectedCellDate.getMonth(), selectedCellDate.getDate() + 7);
        setSelectedCell({date: dateStringFromDate(newDate), row: selectedCell.row});
      }
    }
  }, [maxDate, selectedCell, setSelectedCell]);

  const onSelectSong = useCallback((songSlug?: SongSlug) => {
    if (songSlug) {
      bindSongListEntry(songSlug);
    } else {
      deleteSongListEntry();
    }
    onCloseSongSelector();
    moveDown();
  }, [bindSongListEntry, deleteSongListEntry, moveDown, onCloseSongSelector]);

  const onEditSongEntry = useCallback(() => {
    const editSongList = songList ?? {date, type: songListType, songs: []}

    const songListEntry = editSongList.songs.find(list => list.row === row);
    const hymn = !isBound(songListEntry) ? undefined
      : getHymnFromSlug({songSlug: songListEntry.slug, hymnals, customMusic});
    const initialValue = hymn && `${userVisibleSongNumber(hymn.number)} - ${hymn.title}`;

    if (songListType === SongListType.HymnOfTheMonth && !isInHymnOfTheMonthPlanner) {
      setHymnOfTheMonthPlannerProps({
        songList: editSongList,
        onClose: onCloseHymnOfTheMonthPlanner
      });
    }
    else {
      setSongSelectorProps({
        songList: editSongList,
        onClose: onCloseSongSelector,
        onSongClick: onSelectSong,
        initialValue: initialValue,
        selectSearchValue: true
      });
    }
  }, [
    customMusic,
    date,
    hymnals,
    isInHymnOfTheMonthPlanner,
    onCloseHymnOfTheMonthPlanner,
    onCloseSongSelector,
    onSelectSong,
    row,
    songList,
    songListType
  ]);

  const isSelected = date === selectedCell?.date && row === selectedCell?.row;

  const handleCellClick = useCallback(() => {

    if (getPlatform() === Platform.Mobile) {
      onEditSongEntry();
      return;
    }

    const cellPosition: CellPosition = {
      date: date,
      row: row
    }

    if (setSelectedCell !== undefined && !isSelected) {
      setSelectedCell(cellPosition);
    }
    if (isSelected) {
      onEditSongEntry();
    }
    if (songListType === SongListType.HymnOfTheMonth && !isInHymnOfTheMonthPlanner) {
      onEditSongEntry();
    }
  }, [
    date,
    isSelected,
    isInHymnOfTheMonthPlanner,
    onEditSongEntry,
    row,
    setSelectedCell,
    songListType
  ]);

  const onKeyDown = useCallback((event: React.KeyboardEvent) => {
    const keyIsNumber = event.code === `Digit${event.key}` || event.code === `Numpad${event.key}`;
    const keyIsLetter = event.code === `Key${event.key.toUpperCase()}`;

    if (keyIsNumber || keyIsLetter) {
      setSongSelectorProps({
        songList: songList,
        onClose: onCloseSongSelector,
        onSongClick: onSelectSong,
        initialValue: event.key,
        selectSearchValue: false
      });
      return
    }
  }, [onCloseSongSelector, onSelectSong, songList]);

  const onKeyUp = useCallback((event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        moveDown();
        break;
      case 'ArrowUp':
        moveUp();
        break;
      case 'ArrowRight':
        moveRight();
        break;
      case 'ArrowLeft':
        moveLeft();
        break;
      case 'Tab':
        moveDown();
        break;
      case 'Enter':
        onEditSongEntry();
        break;
      case 'Backspace':
        deleteSongListEntry();
        break;
      case 'Delete':
        deleteSongListEntry();
        break;
      case 'Escape':
        onCloseSongSelector();
    }
  }, [
    moveUp,
    moveDown,
    onEditSongEntry,
    deleteSongListEntry,
    moveLeft,
    moveRight,
    onCloseSongSelector,
  ]);

  useEffect(() => {
    if (isSelected && editSongCellRef.current) {
      editSongCellRef.current.focus();
    }
  }, [isSelected, songSelectorProps]);

  return (
    <>
      {hymnOfTheMonthPlannerProps && <HymnOfTheMonthPlanner key='HOTM planner' {...hymnOfTheMonthPlannerProps}/>}
      {songSelectorProps && <SongSelector key='selector' {...songSelectorProps}/>}
      <EditSongCell
        tabIndex={tabIndexCount++}
        key={`${date}-${row}`}
        $row={row + (gridRowOffset ?? 0)}
        $column={column}
        $empty={!song && songListType !== SongListType.HymnOfTheMonth}
        $sticky={songListType === SongListType.HymnOfTheMonth && !isInHymnOfTheMonthPlanner}
        $isSelected={isSelected}
        onClick={handleCellClick}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        ref={editSongCellRef}
      >
        <SongCellContent $sticky={column === ColumnFullWidth}>
          {
            songListType === SongListType.HymnOfTheMonth &&
            <HymnOfTheMonthHeader key='header'>
              {isInHymnOfTheMonthPlanner ? `${getMonthName(date)}` : `${getMonthName(date)} Hymn of the Month`}
            </HymnOfTheMonthHeader>
          }
          <SongCellSongName>{getDisplayNameForSong({song, hymnals, customMusic})}</SongCellSongName>
        </SongCellContent>
      </EditSongCell>
    </>
  )
}

function getDisplayNameForSong({song, hymnals, customMusic}: {
  song?: SongListEntry,
  hymnals?: HymnalWithHymns[],
  customMusic?: CustomMusic,
}): string | undefined {
  if (!song || !isBound(song)) {
    return undefined;
  }
  const hymn = getHymnFromSlug({songSlug: song.slug, hymnals, customMusic});
  return hymn && `${userVisibleSongNumber(hymn.number)} - ${hymn.title}`;
}

const SongCellPadding = '10px';

const SongCellContent = styled.div<{$sticky: boolean}>`
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  ${props => props.$sticky ? 'position: sticky;' : ''}
  left: 0;
  padding: ${SongCellPadding};
  padding-right: 0;
  /* overcoming Apple's poor CSS support */
  ${getDevice() === Device.iOS ? 'padding-right: 0;' : ''};
  white-space: nowrap;
`;

const SongCellSongNameWidth = `${SongRowWidth} - (${SongCellPadding} * 2)`

const SongCellSongName = styled.div`
  max-width: calc(${SongCellSongNameWidth});
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EditSongCell = styled.div<{
  $row: number,
  $column: number,
  $empty: boolean,
  $sticky: boolean,
  $isSelected: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  min-width: ${SongRowWidth};
  justify-content: space-between;
  grid-row: ${props => props.$row};
  grid-column: ${props => props.$column === ColumnFullWidth ? '1 / -1' : props.$column.toString()};
  /* outline instead of border takes up 0 space */
  outline: 1px solid var(--color-text-lighter);
  height: ${SongRowHeight};
  ${props => props.$sticky ? `
    position: sticky;
    top: calc(${SongRowHeight} + ${LiturgyYearHeight});
    z-index: 4;
    background-color: var(--color-background); 
  ` : ''}
  ${getPlatform() !== Platform.Mobile && `
    border: ${CellHoverOutlineWidth} solid var(--color-background); // avoids text movement on selection
    &:hover {
      background: var(--color-background-selected-item);
      border: ${CellHoverOutlineWidth} solid var(--color-background-selected-item); // avoids white border on hover
      cursor: pointer;
    }
    &:focus {
      border: ${CellHoverOutlineWidth} solid var(--color-text);
      z-index: 2;
    }
  `}
  ${props => props.$sticky ? `
    &:hover {
      z-index: 5;
    }
  ` : ''}
`;

const HymnOfTheMonthHeader = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
`;
