import React, {
  ChangeEvent,
  FormEvent,
  PointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import '../util/shared.css';
import {hymnalsDir, churchHymnalIconPath} from '../../common/paths';
import {Header, HeaderHeight} from './header';
import './player_page.css';
import './footer.css';
import {ReactComponent as PlayButton} from '../assets/play-circle.svg';
import {ReactComponent as PauseButton} from '../assets/pause-circle.svg';
// REPEAT_BUTTON
// import {ReactComponent as Repeat} from '../assets/repeat-rounded.svg';
import {ReactComponent as SkipForwardIcon} from '../assets/play-skip-forward.svg';
import {ReactComponent as SkipBackwardIcon} from '../assets/play-skip-back.svg';
import {ReactComponent as OutlinedHeartIcon} from '../assets/heart-outline.svg';
import {ReactComponent as HeartIcon} from '../assets/heart.svg';
import {ReactComponent as ArrowRight} from '../assets/arrow-forward.svg';
import {ReactComponent as ArrowLeft} from '../assets/arrow-back.svg';
import {RequestSongPage} from './request_song_page';
import {Slider, SliderBase} from '../util/slider';
import {getTextUrlForHymn} from '../util/path';
import {ensureExists, ensureUnreachable, userVisibleSongNumber} from "../../common/util";
import {ReportSongIssue} from '../player/report_song_issue';
import {favorites} from '../data/favorites';
import {isDesktop} from 'react-device-detect';
import {SlideMenu} from '../player/slide_menus';
import {
  defaultVerseCount,
  localStorageGet,
  LocalStorageKey,
  SongIntroduction,
  Voice,
  VoiceValues
} from '../data/client_local_storage';
import {useLocalStorage} from '../data/use_local_storage';
import {useSwipeable} from 'react-swipeable';
import {VerseBar} from '../player/verse_bar';
import {Hymn, HymnIssue} from "../../common/model";
import {isWKWebView} from "../authentication/apple_login";
import styled from 'styled-components/macro';
import {ReactComponent as VocalsIcon} from "../assets/vocals.svg";
import {ReactComponent as DocumentTextOutline} from "../assets/document-text-outline.svg";
import {songViews} from '../data/song_views';
import {Stopwatch} from '../util/stopwatch';
import {PdfViewerPage} from './pdf_viewer_page';
import {useUserAttributes} from '../data/use_user_attributes';
import {useHousehold} from '../data/use_household';
import {setSongTempoForChurch, useChurch} from '../data/use_church';
import {useSequencer} from '../player/use_sequencer';
import {getSypMeasureBeatCount, parseSypMusicFile, SypMusicFileKey} from '../../common/syp_file';
import {alert} from '../util/confirm';
import {singingPlanCompletionDurationMs, useSingingPlanCompletions} from '../data/use_singing_plan_completions';
import {dateStringFromDate} from '../../common/date_string';
import {Spinner} from '../util/spinner';
import {useLongPress} from '../util/use_long_press';
import {NetworkError} from '../util/network';
import useWindowDimensions from '../util/useWindowDimensions';
import {QuarterNote} from '../util/shared';

interface Props {
  hymns: Hymn[];  //optional list of songs to be played
  onBack?: () => void;  //optional callback function for back button in header
}

enum Display {PDF, Info, SongSettings}

export const PlayerPage = ({hymns, onBack}: Props) => {
  const [hymn, setHymn] = useState<Hymn>(hymns[0]);
  const [isReportIssueDialogVisible, setIsReportIssueDialogVisible] = useState(false);
  const [slideMenu, setSlideMenu] = useState(false);
  const [txtFileParsed, setTxtFileParsed] = useState(false);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [networkError, setNetworkError] = useState<NetworkError | undefined>();
  const initiallyDisplayPDF = hymn.issue === HymnIssue.Missing || networkError;
  const [display, setDisplay] = useState(initiallyDisplayPDF ? Display.PDF : Display.SongSettings);
  const [playing, setPlaying] = useState(false);
  const {church} = useChurch();
  const songSlug = hymn.slug;
  const {household} = useHousehold();
  const [sliderTimestamp, setSliderTimestamp] = useState(0);
  const [currentVerse, setCurrentVerse] = useState(0);
  const [selectedVoice, setSelectedVoice] = useState('')
  const [isFavorite, setIsFavorite] = useState(() =>
    favorites.includes(hymn)
  );
  const [showVocalsByDefault, setShowVocalsByDefault] = useLocalStorage(LocalStorageKey.ShowVocalsByDefault);
  const [vocalsEnabled, setVocalsEnabled] = useState(false);
  const [stopwatch] = useState(() => new Stopwatch());
  const [user] = useLocalStorage(LocalStorageKey.User);
  const {isInternalUser} = useUserAttributes();
  const [tempoSliderValue, setTempoSliderValue] = useState<number>(1); /* see also currentTempo */
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    if (
      networkError !== NetworkError.MidiDownloadFailed &&
      networkError !== NetworkError.MidiFileParseFailed &&
      networkError !== NetworkError.TextDownloadFailed &&
      networkError !== NetworkError.TextFileParseFailed
    ) {
      return;
    }
    setDisplay(Display.PDF);
  }, [networkError, hymn.basePath]);

  // todo(hewitt): preload the browser forward history with the PDF page to swipe from right to left
  //   the challenge is that useEffect is called in response to navigateTo, even with the 'ignore' option
  // const {location, navigateTo} = useLocation();
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search)
  //   searchParams.set('showPDF', 'true');
  //   const pdfURL = location.pathname + '?' + searchParams.toString();
  //   navigate(pdfURL, LocationNavigationOption.Ignore);
  //   window.history.back();
  // })

  const addFavorite = useCallback(async () => {
    if (user === "guest") {
      await alert({confirmation:
          <span>To add this song to Favorites, please sign into an Individual account in Settings.</span>
      });
      return;
    }
    favorites.add(hymn);
    setIsFavorite(true)
  }, [hymn, user]);

  const removeFavorite = useCallback(() => {
    favorites.remove(hymn)
    setIsFavorite(false)
  }, [hymn]);

  const renderFavoriteButton = useCallback(() => {
    if (isCustomSong(hymn)) {
      // custom songs do not have a hymnal, so cannot yet be favorited
      // TODO(hewitt): Convert favorites table to JSON blob in order to store custom song info
      //               Best to wait until we nix Coda and have a better idea of how we will store custom music
      return null;
    }
    if (isFavorite) {
      return <HeartIcon className="heartIcon" onClick={removeFavorite}/>
    } else {
      return (
        <OutlinedHeartIcon className="heartIcon" onClick={addFavorite}>
          Add Favorite
        </OutlinedHeartIcon>
      );
    }
  }, [addFavorite, isFavorite, removeFavorite, hymn]);


  const title =
    (hymn.number && !isCustomSong(hymn) ? userVisibleSongNumber(hymn.number) + ' - ' : '') +
    ensureExists(hymn.title);

  const isLoaded = txtFileParsed && readyToPlay;

  // cache for performance during playback
  const [voiceValues, setVoiceValues] = useLocalStorage(LocalStorageKey.VoiceValues, () =>
    hymn.vocalPartCount === "2" ? {
      [Voice.Soprano]: 1,
      [Voice.Alto]: 0.5,
      [Voice.Tenor]: 1, // Turn the Tenor (Bass) all the way up by default for 2 vocal parts
      [Voice.Bass]: 0.5,
      [Voice.Piano]: 0.5,
    } : undefined
  );

  useEffect(() => {
    (async () => {
      const txtFilePath= getTextUrlForHymn(hymn);
      const response = await fetch(txtFilePath);
      if (!response?.ok) {
        console.log(`Error fetching TXT file ${txtFilePath}: ${response.statusText}`);
        setNetworkError(NetworkError.TextDownloadFailed);
        return;
      }
      try {
        const content = await response.text();
        parseTextFile(content);
      } catch (error: any) {
        console.log(`Error parsing TXT file ${txtFilePath}: ${error.message}`);
        setNetworkError(NetworkError.TextFileParseFailed);
        return;
      }

      function parseTextFile(content: string) {
        const sypFile = parseSypMusicFile(content);
        const {metadata, measures} = sypFile;
        const maybeVerseCount = metadata[SypMusicFileKey.VerseCount];
        hymn.verseCount = maybeVerseCount ? Number(maybeVerseCount) : undefined;
        hymn.author = metadata[SypMusicFileKey.Author];
        hymn.composer = metadata[SypMusicFileKey.Composer];
        hymn.harmony = metadata[SypMusicFileKey.Harmony];
        hymn.copyright = metadata[SypMusicFileKey.Copyright];
        hymn.key= metadata[SypMusicFileKey.Key];
        hymn.vocals = metadata[SypMusicFileKey.Vocals];
        hymn.vocalPartCount = metadata[SypMusicFileKey.VocalPartCount];
        if (!vocalsEnabled && measures.length > 0) {
          hymn.beatsPerMeasure = measures.map(measure => getSypMeasureBeatCount(measure, {includeFermatas: true}));
          hymn.firstMeasureNumber = measures[0].number;
          const totalSongBeats = hymn.beatsPerMeasure.reduce((accum, value) => accum + value, 0);
          if (totalSongBeats) {
            const measurePercentages: number[] = [];
            let percentTotal = 0;
            for (const measureBeats of hymn.beatsPerMeasure) {
              measurePercentages.push(roundMeasurePercent(percentTotal));
              percentTotal += 100 * measureBeats / totalSongBeats;
            }
            hymn.measurePercentages = measurePercentages;
          }
        }
        setTxtFileParsed(true);
      }
    })();
  }, [hymn, vocalsEnabled]);

  const {markSongCompleted} = useSingingPlanCompletions();

  // Record a song view whenever the hymn changes or this page closes
  useEffect(() => {
    return () => {
      if (stopwatch.totalTimeMs === 0) {
        return;
      }
      const durationMs = stopwatch.totalTimeMs;
      songViews.add({songSlug: hymn.slug, durationMs});
      if (durationMs >= singingPlanCompletionDurationMs) {
        const dateString = dateStringFromDate(new Date());
        if (songSlug) {
          markSongCompleted(dateString, songSlug);
        }
      }
      stopwatch.clear();
      void songViews.uploadToServer();
    };
  }, [stopwatch, hymn, markSongCompleted, songSlug]);

  // Count time viewing PDF toward song view
  useEffect(() => {
    const pdfStopwatchSubscription = display === Display.PDF ? stopwatch.subscribe() : undefined;
    return () => {
      if (pdfStopwatchSubscription) {
        stopwatch.unsubscribe(pdfStopwatchSubscription);
      }
    }
  }, [display, stopwatch]);

  const [songIntroduction] = useLocalStorage(LocalStorageKey.SongIntroduction);
  const [rawVerseCount] = useLocalStorage(LocalStorageKey.VerseCount);
  const verseCount = rawVerseCount ?? hymn.verseCount ?? defaultVerseCount;

  const hidePlayer = (hymn.issue === HymnIssue.Music && !isInternalUser) || hymn.issue === HymnIssue.Missing;

  const onHymnChange = useCallback((hymn: Hymn) => {
    setHymn(hymn);
  }, []);

  const onNetworkError = useCallback((networkError: NetworkError) => {
    console.log(`setting network error: ${networkError}`);
    setNetworkError(networkError);
  }, []);

  // This is needed because onReadyToPlay wants to call updateLocationSlider, which references the sequencer.
  // Since we pass onReadyToPlay to create the sequencer, that creates a circular dependency.
  // So, we use a level of indirection to trigger the location update (see related useEffect below).
  const [refreshLocationSlider, setRefreshLocationSlider] = useState(false);
  const triggerLocationSliderUpdate = useCallback(() => {
    setRefreshLocationSlider(!refreshLocationSlider);
  }, [refreshLocationSlider]);

  const onVerseChange = useCallback((verse: number) => {
    setCurrentVerse(verse);
    triggerLocationSliderUpdate();
  }, [triggerLocationSliderUpdate]);

  const onReadyToPlay = useCallback(() => {
    setReadyToPlay(true);
    triggerLocationSliderUpdate();
  }, [triggerLocationSliderUpdate]);

  // TODO(hewitt): current percentage should be coming back as a value & updating regularly
  //               timer might move back down into sequencer (push model rather than pull model)
  //               will minimize the amount of code that runs on each update
  const {sequencer, defaultTempo, setCurrentTempo, setSongIntroductionPercent} = useSequencer({
    hymns,
    onHymnChange,
    setPlaying,
    onReadyToPlay,
    onNetworkError,
    onVerseChange: onVerseChange,
    stopwatch,
    updateLocationSlider: triggerLocationSliderUpdate,
  });

  // REPEAT_BUTTON
  // const [repeat, setRepeat] = useLocalStorage(StorageKey.Repeat);
  //
  // const updateRepeat = useCallback((value: boolean) => {
  //   setRepeat(value);
  // }, [setRepeat]);

  const onVoiceButtonClick = (event: any) => {

    const voice = event.target.innerHTML.toLowerCase() as Voice;

    if (voice === selectedVoice) {
      setSelectedVoice('');
      setVoiceValues({
        ...voiceValues,
        [Voice.Soprano]: 1,
        [Voice.Alto]: 0.5,
        [Voice.Tenor]: 0.5,
        [Voice.Bass]: 0.5,
        [Voice.Piano]: 0.5,
      });
    } else {
      setSelectedVoice(voice);
      setVoiceValues({
        ...voiceValues,
        [Voice.Soprano]: 0,
        [Voice.Alto]: 0,
        [Voice.Tenor]: 0,
        [Voice.Bass]: 0,
        [Voice.Piano]: 0,
        [voice]: 1
      });
    }
  }

  const onVoiceButtonLongPress = (event: any) => {

    if (sequencer.isPlaying) return;

    const voice = event.target.innerHTML.toLowerCase() as Voice;

    console.log(voice + ' long press');
  }

  const handleVoiceButtonInput = useLongPress(onVoiceButtonLongPress, onVoiceButtonClick);

  function handleVoiceSliderChange(voice: Voice, value: number) {
    setSelectedVoice('');
    setVoiceValues(fixVoiceValues({...voiceValues, [voice]: value}));
  }

  function renderUpperDiv() {
    return <div className='upperDiv'>
      <form id='slider-form'>
        <table className='sliderTable'>
          <colgroup>
            <col className='sliderLabelColumn'/>
          </colgroup>
          <tbody>
          {
            Object.values(Voice).map(voice => {
              if (voice === Voice.Piano && !vocalsEnabled) {
                return null;
              }
              // only show soprano & tenor if two parts
              if (voice !== Voice.Soprano && voice !== Voice.Tenor && hymn.vocalPartCount === "2") {
                return null;
              }
              function getVoiceName(): string {
                let voiceName: string;
                if (hymn.vocalPartCount !== "2") {
                  voiceName = voice;
                } else if (voice === Voice.Soprano) {
                  voiceName = 'Treble';
                } else if (voice === Voice.Tenor) {
                  voiceName = 'Bass';
                } else {
                  voiceName = voice;
                }
                return voiceName.charAt(0).toUpperCase() + voiceName.slice(1);
              }
              return (
                <tr key={voice}>
                  <td className={voice === Voice.Piano ? 'pianoVoiceSpacer' : ''}>
                    <VoiceButton
                      className={voice === selectedVoice ? 'selectedVoice' : ''}
                      {...handleVoiceButtonInput}
                    >
                      <SliderLabel id={`${getVoiceName()}-label`}>
                        {getVoiceName()}
                      </SliderLabel>
                    </VoiceButton>
                  </td>
                  <td className={`sharedSliderTd ${voice === Voice.Piano ? 'pianoVoiceSpacer' : ''}`}>
                    <Slider
                      id={voice}
                      className={`letter_${getVoiceName().charAt(0).toLowerCase()}`}
                      defaultValue={voiceValues[voice]}
                      value={voiceValues[voice]}
                      min={0}
                      max={1}
                      step={0.01}
                      onPointerUp={(value) => {
                        setSliderTimestamp(Date.now());
                        handleVoiceSliderChange(voice, value);
                      }}
                    />
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </form>
    </div>;
  }

  const onTempoSliderChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTempoSliderValue(Number(event.target.value));
  }, []);

  const currentTempo = defaultTempo && tempoSliderValue && Math.round(defaultTempo * tempoSliderValue);

  const onTempoSliderPointerUp = useCallback(() => {
    setSliderTimestamp(Date.now());
    if (currentTempo) {
      setCurrentTempo(currentTempo);
    }
  }, [currentTempo, setCurrentTempo]);

  const onSetOrganizationTempo = useCallback(() => {
    const songTempo = currentTempo;
    if (!church || !songSlug || !songTempo) {
      return;
    }
    void setSongTempoForChurch({church, songSlug, songTempo});
    const tempoSlider = document.getElementById('tempo-slider') as HTMLInputElement;
    tempoSlider.value = (1).toString();
    setTempoSliderValue(1);
  }, [church, currentTempo, songSlug]);

  const renderTempoSlider = useCallback(() => {
    const displayTempoButton = (
      readyToPlay &&
      currentTempo &&
      currentTempo !== defaultTempo &&
      household?.isAdminForChurchId === church?.id
    );
    const tempoWidth = currentTempo && currentTempo >= 100 ? '3ch' : '2ch';
    const tempoLabel = displayTempoButton ? (
      <TempoButton onClick={onSetOrganizationTempo}>
        <div>Set to</div>
        <div>{currentTempo}</div>
      </TempoButton>
    ) : (
      <TempoLabel>
        <QuarterNote hidden={!currentTempo} />
        <SliderLabel hidden={!currentTempo}>=</SliderLabel>
        <SliderLabel key='tempo-output' style={{width: tempoWidth}}>{currentTempo ?? '-'}</SliderLabel>
      </TempoLabel>
    );

    return (
      <tbody>
        {vocalsEnabled ?
          null : // piano slider
          <tr>
            <td>
              {tempoLabel}
            </td>
            <td className='sharedSliderTd'>
              <SliderBase className='tempo' type='range' id='tempo-slider' defaultValue={1} min={0.5} max={1.5}
                     step={0.01}
                     onChange={onTempoSliderChange}
                     onPointerUp={onTempoSliderPointerUp}
              />
            </td>
          </tr>
        }
      </tbody>
    )
  }, [
    church?.id,
    currentTempo,
    defaultTempo,
    household?.isAdminForChurchId,
    onSetOrganizationTempo,
    onTempoSliderChange,
    onTempoSliderPointerUp,
    readyToPlay,
    vocalsEnabled,
  ]);

  const [pointerDown, setPointerDown] = React.useState(false);
  const [experiencingUserInputOnTimeSlider, setExperiencingUserInputOnTimeSlider] = React.useState(false);
  const [locationValue, setLocationValue] = useState("");
  const locationSlider = useRef<HTMLInputElement>(null);

  function roundMeasurePercent(percent: number): number {
    return Math.round(percent * 100) / 100;
  }

  const getMeasureFromPercent = useCallback((percent: number): {
    measureNumber: number | undefined;
    measurePercent: number | undefined;
  } => {
    // measure-based song location currently does not work for vocals
    if (
      hymn.firstMeasureNumber !== undefined &&
      hymn.measurePercentages &&
      hymn.measurePercentages.length > 1 &&
      !vocalsEnabled
    ) {
      const measurePercentages = hymn.measurePercentages;
      for (let index = 0; index < measurePercentages.length; index++) {
        if (measurePercentages[index] > percent) {
          return {measureNumber: index - 1 + hymn.firstMeasureNumber, measurePercent: measurePercentages[index - 1]};
        }
      }
      return {
        measureNumber: hymn.firstMeasureNumber + measurePercentages.length - 1,
        measurePercent: measurePercentages[measurePercentages.length - 1],
      };
    }
    return {measureNumber: undefined, measurePercent: undefined};
  }, [hymn.firstMeasureNumber, hymn.measurePercentages, vocalsEnabled]);

  const updateLocationValue = useCallback((percent: number) => {
    const duration = sequencer.duration;
    if (duration === undefined || !readyToPlay) {
      return;
    }
    const {measureNumber} = getMeasureFromPercent(percent);
    if (measureNumber !== undefined) {
      setLocationValue(Math.trunc(measureNumber).toString());
      return;
    }
    const secondsElapsed = duration * (percent / 100);
    const minutes = Number(Math.trunc(secondsElapsed / 60));
    const seconds = (Math.round(secondsElapsed % 60)).toString().padStart(2,'0');
    const timeDisplayValue = minutes + ':' + seconds;
    setLocationValue(timeDisplayValue);
  }, [sequencer, getMeasureFromPercent, readyToPlay]);

  const updateLocationSlider = useCallback((percentOverride?: number) => {
    const rawPercent = percentOverride ?? sequencer.getCurrentPercent();
    if (
      (percentOverride === undefined && experiencingUserInputOnTimeSlider) ||
      !locationSlider.current ||
      rawPercent === undefined
    ) {
      return;
    }
    const percent = roundMeasurePercent(rawPercent);
    locationSlider.current.value = percent.toString();
    updateLocationValue(percent);
  }, [experiencingUserInputOnTimeSlider, sequencer, updateLocationValue]);

  useEffect(() => {
    if (refreshLocationSlider || songIntroduction) {} // avoid unused dependency warning
    updateLocationSlider();
  }, [refreshLocationSlider, songIntroduction, updateLocationSlider]);

  useEffect(() => {
    let interval: number | undefined;
    if (playing) {
      interval = window.setInterval(updateLocationSlider, 100);
    }
    return () => {interval && window.clearInterval(interval)};
  }, [updateLocationSlider, playing]);

  const getChunkyPercentFromSliderValue = useCallback((percent: number): number => {
    const {measurePercent} = getMeasureFromPercent(percent);
    if (measurePercent !== undefined) {
      return measurePercent;
    }
    return Number(percent);
  }, [getMeasureFromPercent]);

  const onLocationSliderInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    setExperiencingUserInputOnTimeSlider(true);
    const chunkyPercent = getChunkyPercentFromSliderValue(Number(event.currentTarget.value));
    updateLocationSlider(chunkyPercent);

    // Normally we don't set the slider time until the pointer up event.
    // However, iOS sends the onInput *after* onPointerUp when the user taps
    // the slider instead of drag/drop (bad Apple).
    // So, if the pointer is already up, we let onInput set the slider time.
    if (!pointerDown) {
      sequencer.setNewTime(chunkyPercent);
      setSliderTimestamp(Date.now());
    }
  }, [sequencer, pointerDown, updateLocationSlider, getChunkyPercentFromSliderValue]);

  useEffect(() => {
    if (vocalsEnabled || !songIntroduction || songIntroduction === SongIntroduction.FullSong || !sequencer.duration) {
      setSongIntroductionPercent(0);
      return;
    }
    console.log(`past condition`);
    let desiredIntroDuration: number;
    switch (songIntroduction) {
      case SongIntroduction.LastFiveSeconds:
        desiredIntroDuration = 5;
        break;
      case SongIntroduction.LastTenSeconds:
        desiredIntroDuration = 10;
        break;
      case SongIntroduction.LastFifteenSeconds:
        desiredIntroDuration = 15;
        break;
      default:
        ensureUnreachable(songIntroduction);
    }
    const desiredPercentage = 100 * Math.max(0, sequencer.duration - desiredIntroDuration) / sequencer.duration;
    const chunkyPercent = getChunkyPercentFromSliderValue(desiredPercentage);
    console.log(`desiredIntroDuration: ${desiredIntroDuration}, desiredPercentage: ${desiredPercentage}, chunkyPercent: ${chunkyPercent}`);
    setSongIntroductionPercent(chunkyPercent);
  }, [
    songIntroduction,
    sequencer,
    sequencer.duration,
    setSongIntroductionPercent,
    getChunkyPercentFromSliderValue,
    vocalsEnabled
  ]);

  const onLocationSliderPointerUp = useCallback((event: PointerEvent<HTMLInputElement>) => {
    const chunkyPercent = getChunkyPercentFromSliderValue(Number(event.currentTarget.value));
    updateLocationSlider(chunkyPercent);
    sequencer.setNewTime(chunkyPercent);
    setSliderTimestamp(Date.now());
    setPointerDown(false);
    setExperiencingUserInputOnTimeSlider(false);
  }, [sequencer, updateLocationSlider, getChunkyPercentFromSliderValue]);

  const onLocationSliderPointerDown = useCallback(() => {
    setPointerDown(true);
    setExperiencingUserInputOnTimeSlider(true);
  }, []);

  // note that CC 2002 contains some single measure, hand-typed music - one measure isn't useful anyway
  const isMeasureBasedUI = !vocalsEnabled && hymn.measurePercentages && hymn.measurePercentages.length > 1;

  const locationValueStyle = isMeasureBasedUI ? {width: `${Number(locationValue) >= 10 ? 2 : 1}ch`} : {};

  const measureTickMarksGradient = useMemo(() => {
    if (vocalsEnabled || !isMeasureBasedUI || !hymn.measurePercentages) {
      return "";
    }
    const background = 'var(--color-text)';
    const tickMark = 'var(--color-background)';
    let gradientParts: string[] = [];
    gradientParts.push(background);
    function pushTickMark(percent: number) {
      gradientParts.push(`${background} ${percent}%`);
      gradientParts.push(`${tickMark} ${percent}%`);
      gradientParts.push(`${tickMark} ${percent + 0.5}%`);
      gradientParts.push(`${background} ${percent + 0.5}%`);
    }
    for (const percent of hymn.measurePercentages) {
      if (percent > 0 && percent < 100) {
        pushTickMark(percent);
      }
    }
    return gradientParts.join(',');
  }, [hymn.measurePercentages, isMeasureBasedUI, vocalsEnabled]);

  const locationThumbVisible = isLoaded && locationValue !== undefined;

  function renderTimeSlider() {
    return (
      <tbody>
      <tr>
        <td className='locationValue'>
          <SongLocation>
            <MeasurePrefix className='sliderLabel' hidden={!isMeasureBasedUI}>
              m.
            </MeasurePrefix>
            <SliderLabel style={locationValueStyle}>
              {readyToPlay ? (locationValue || "0") : '-'}
            </SliderLabel>
          </SongLocation>
        </td>
        <td className='sharedSliderTd'>
          <LocationSlider
            className={'locationSliderThumb' + (locationThumbVisible ? '' : ' locationSliderThumbHidden')}
            type='range'
            id='location-slider'
            ref={locationSlider}
            onInput={onLocationSliderInput}
            onPointerUp={onLocationSliderPointerUp}
            onPointerDown={onLocationSliderPointerDown}
            defaultValue={0}
            min={0}
            max={100}
            step={0.01}
            $gradient={measureTickMarksGradient}
          />
        </td>
      </tr>
      </tbody>
    )
  }

  const handlePlay = useCallback(() => {
    if (localStorageGet(LocalStorageKey.PdfAutoDisplay)) {
      setDisplay(Display.PDF)
      setTimeout(() => sequencer.play(), 300)
    } else {
      sequencer.play();
    }
  }, [sequencer]);

  const onPause = useCallback(() => {
    sequencer.pause();
    updateLocationSlider();
  }, [sequencer, updateLocationSlider]);

  useEffect(() => {
    // Register for the iPhone lock screen
    // See this tutorial: https://web.dev/media-session/
    if ("mediaSession" in navigator && hymn && playing) {
      const src = isCustomSong(hymn) ? churchHymnalIconPath : `/${hymnalsDir}/${hymn.hymnal}/icon.png`;
      navigator.mediaSession.metadata = new MediaMetadata({
        title: hymn.title,
        artist: hymn.author ?? hymn.composer ?? '',
        album: hymn.hymnal,
        artwork: [
          {src, sizes: '128x128', type: 'image/png'},
        ]
      });
      navigator.mediaSession.setActionHandler('play', () => sequencer.play());
      navigator.mediaSession.setActionHandler('pause', onPause);
      if (hymns.length > 1) {
        navigator.mediaSession.setActionHandler('previoustrack', () => sequencer.previousSong());
        navigator.mediaSession.setActionHandler('nexttrack', () => sequencer.nextSong());
      }
    }
  }, [hymn, hymns.length, sequencer, onPause, playing]);

  const onVerseClick = useCallback((verseIndex: number) => {
    sequencer.changeVerse(verseIndex);
  }, [sequencer]);

  const toggleVocalsEnabled = useCallback(() => {
    const newValue = !vocalsEnabled;
    setReadyToPlay(false);
    sequencer.setVocalsEnabled(newValue);
    setVocalsEnabled(newValue);
    setShowVocalsByDefault(newValue);
  }, [vocalsEnabled, sequencer, setShowVocalsByDefault]);

  useEffect(() => {
    if (hymn.vocals && showVocalsByDefault && !vocalsEnabled) {
      toggleVocalsEnabled()
    }
  }, [hymn.vocals, showVocalsByDefault, vocalsEnabled, toggleVocalsEnabled]);

  function handleSwipe() {
    let diff = Date.now() - sliderTimestamp;
    if (diff > 50) {
      setDisplay(Display.PDF)
    }
  }

  const swipeLeftHandler = useSwipeable({
    onSwipedLeft: () => handleSwipe(),
  });

  const renderPlayPauseButton = useCallback(() => {

    // if not playing, display play button
    if (!playing) {
      return (
        <button
          id='play-button'
          className='playPauseButton'
          onClickCapture={() => handlePlay()}>
          <PlayButton className='playPauseIcon'/>
        </button>
      )
    }
    // if playing, display pause button
    return (
      <button
        id='pause-button'
        className='playPauseButton'
        onClick={onPause}>
        <PauseButton className='playPauseIcon'/>
      </button>
    )
  }, [
    handlePlay,
    playing,
    onPause,
  ]);

  const renderPlaybackButtons = useCallback(() => {

    // if network error, display error
    if (networkError) {
      return (
        <label className='loading' id='error'>Error</label>
      )
    }
    // if not loaded, display spinner
    if (!isLoaded) {
      return (<Spinner />)
    }
    // if playing more than one song, return playback buttons
    if (hymns!.length > 1) {
      return (
        <>
          <div style={{position: 'relative', width: '60px'}}>
            <button onClick={() => sequencer.previousSong()} className="skipIconLeft">
              <SkipBackwardIcon/>
            </button>
          </div>
          <div>
            {renderPlayPauseButton()}
          </div>
          <div style={{position: 'relative', width: '60px'}}>
            <button onClick={() => sequencer.nextSong()} className="skipIconRight">
              <SkipForwardIcon/>
            </button>
          </div>
        </>
      )
    }
    // return play/pause button by default
    return (
      <div>
        {renderPlayPauseButton()}
      </div>
    )

  }, [
    hymns,
    isLoaded,
    networkError,
    renderPlayPauseButton,
    sequencer,
  ]);

  const renderVocalsButton = useCallback(() => {

    return (
      <VocalsButtonBlock $visible={Boolean(hymn.vocals)}>
        <VoiceButton onClick={toggleVocalsEnabled}>
          <VocalsButtonContentWrapper $enabled={vocalsEnabled}>
            <VocalsIcon className="vocalsIcon"/>
            <SliderLabel>
              Vocals
            </SliderLabel>
          </VocalsButtonContentWrapper>
        </VoiceButton>
      </VocalsButtonBlock>
    )
  }, [
    hymn.vocals,
    toggleVocalsEnabled,
    vocalsEnabled,
  ]);

  const renderVerses = useCallback(() => {
    return (
      <div hidden={vocalsEnabled || (verseCount === 1 && !songIntroduction)} id='lowerPlayerIcons'>
        <div style={{display: 'inline-block'}}>
          <VerseBar
            intro={songIntroduction !== undefined}
            verseCount={verseCount}
            selectedVerse={currentVerse}
            onClick={onVerseClick}
          />
        </div>
      </div>
    )
  }, [
    onVerseClick,
    songIntroduction,
    currentVerse,
    verseCount,
    vocalsEnabled,
  ]);

  const renderButtons = useCallback(() => {

    return (
      <div className='bottomDiv' id='bottom-div' {...swipeLeftHandler}>
        <div className="icons" style={{position: "relative"}}>
          <div id="playerIcons" style={{position: "relative"}}>
            <div id='upperPlayerIcons'>

              <div id='heartContainer'>
                <div>
                  {renderFavoriteButton()}
                </div>
              </div>

              <div id='playbackIconsContainer'>
                {renderPlaybackButtons()}
              </div>

              <div id='repeatContainer'>
                <DocumentTextOutline className="repeatButton" onClick={() => setDisplay(Display.PDF)}/>
                {/* REPEAT_BUTTON */}
                {/*<div hidden={!repeat}>*/}
                {/*  <Repeat className="repeatButton" onClick={() => updateRepeat(false)}/>*/}
                {/*</div>*/}
                {/*<div hidden={repeat}>*/}
                {/*  <Repeat className="repeatButton greyedOutSvg" onClick={() => updateRepeat(true)}/>*/}
                {/*</div>*/}
              </div>
            </div>

            {renderVocalsButton()}
            {renderVerses()}

          </div>
        </div>
      </div>
    );
  }, [
    renderFavoriteButton,
    renderPlaybackButtons,
    renderVerses,
    renderVocalsButton,
    swipeLeftHandler,
  ]);

  function renderLowerDiv() {
    return (
      <div className='lowerDiv'>
        <form id='second-slider-form'>
          <table className='tempoTimeSliderTable'>
            {renderTempoSlider()}
            {renderTimeSlider()}
          </table>
        </form>
        {renderButtons()}
      </div>
    );
  }

  function renderPlayerUI() {
    return (
      <div className='playerPageContent'>
        {renderUpperDiv()}
        {renderLowerDiv()}
      </div>
    )
  }

  function renderControls() {
    return (
      <div style={{height: '100%'}}
           key='player'
      >
        <div style={{...(!hidePlayer && {display: 'none'}), height: '100%'}}>
          {
            hymn.issue === HymnIssue.Music
              ? <RequestSongPage
                issue={hymn.issue}
                hymnalName={ensureExists(hymn.hymnal)}
                songNumber={hymn.number}
                onDisplayPDF={() => setDisplay(Display.PDF)}
              />
              : <>
                {/* keeps PDF visible for some reason when no .mid file... */}
                <div style={{...(!hidePlayer && {display: 'none'}), width: '100vw'}}/>
              </>
          }
        </div>
        <div style={{...(hidePlayer && {display: 'none'})}}>
          {renderPlayerUI()}
        </div>
      </div>
    )
  }


  function renderStickyFooter() {
    return (
      <div id="stickyFooter" hidden={hidePlayer} style={isWKWebView() ? {bottom: 0} : {bottom: 20}}>

        <div  style={{display: !isDesktop || display !== Display.PDF ? "none" : "flex"}} id="desktopBackPrompt" onClick={() => setDisplay(Display.SongSettings)}>
          <ArrowLeft  className="arrowPointer"/>
        </div>

        <div id="dotsContainer" style={isDesktop ? {display: 'none'} : {display:'flex'}}>
         <div
           style={display === Display.PDF ? {backgroundColor: "#b0b0b0"} : {backgroundColor: "#606060"}}
           onClick={() => setDisplay(Display.SongSettings)}
           className="dot"/>
         <div style={{width: 10}}/>

         <div
           style={display === Display.SongSettings ? {backgroundColor: "#b0b0b0"} : {backgroundColor: "#888888"}}
           onClick={() => setDisplay(Display.PDF)}
           className="dot"/>
        </div>

        <div  style={{display: !isDesktop || display !== Display.SongSettings ? "none" : "flex"}} id="desktopPdfPrompt" onClick={() => setDisplay(Display.PDF)}>
          <div  className="promptText">PDF</div>
          <ArrowRight  className="arrowPointer"/>
        </div>
      </div>
    )
  }

  const swipeRightHandler = useSwipeable({
    onSwipedRight: () => setDisplay(Display.SongSettings),
  });

  const onClosePdf = useCallback(() => {
    if (initiallyDisplayPDF && onBack) {
      onBack();
      return;
    }
    setDisplay(Display.SongSettings);
  }, [initiallyDisplayPDF, onBack]);

  const issue = hymn.issue ?? (networkError === NetworkError.MidiDownloadFailed ? HymnIssue.Missing : undefined);

  return (
    <div {...swipeRightHandler}>
      <div style={{transform: display === Display.PDF ? "translateY(-60px)" : "", transition: "transform 350ms"}}>
        <ReportSongIssue
          close={() => setIsReportIssueDialogVisible(false)}
          visible={isReportIssueDialogVisible}
          hymn={hymn}
        />
        <Header
          title={ensureExists(title)}
          onBack={onBack}
          onKebabClick={() => setSlideMenu(true)}
        />
      </div>
      <SlideMenu
        show={slideMenu}
        showModal={() => setIsReportIssueDialogVisible(true) }
        close={() => setSlideMenu(false)}
        displayPdf={() => setDisplay(Display.PDF)}
        hymn={hymn}/>
      <div id="playerPage" style={{left: display === Display.PDF ? "-100%" : 0}}>
        {renderControls()}
        <PdfViewerPage
          hymn={hymn}
          height='100vh'
          headerHeight={HeaderHeight}
          width={windowDimensions.width}
          issue={issue}
          onClose={onClosePdf}
          isPDFVisible={display === Display.PDF}
        />
      </div>
      {renderStickyFooter()}
    </div>
  );
}

const VocalsButtonBlock = styled.div<{$visible?: boolean}>`
  display: ${props => props.$visible ? 'flex' : 'none'};
  justify-content: center;
`;

const VocalsButtonContentWrapper = styled.div<{$enabled: boolean}>`
  background-color: ${props => props.$enabled ? 'rgba(82, 0, 187, 0.2)' : 'inherit'};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
`;

function fixVoiceValues(voiceValues: VoiceValues): VoiceValues {
  // TODO(hewitt): Customers have reported the crash `TypeError: The provided value is non-finite`
  //               when adjusting vocal volumes.  Thus, it appears that infinite volume values are
  //               being recorded -> we attempt to prevent this here.
  return Object.fromEntries(Object.entries(voiceValues).map(([key, value]) =>
    [key, Math.min(1, value)]
  )) as unknown as VoiceValues;
}

export function isCustomSong(hymn: Hymn): boolean {
  // not the best way, but works for now
  return Boolean(hymn.basePath);
}

const VoiceButton = styled.div`
  overflow: hidden;
  box-shadow: 1.3px 1.3px 3px 0 #b0b0b0;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
`;

const SliderLabel = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: larger;
  cursor: inherit;
`;

const TempoLabelButtonHeight = '60px';

const TempoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 6px;
  height: ${TempoLabelButtonHeight};
`;

const TempoButton = styled(VoiceButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${TempoLabelButtonHeight};
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.2rem;
`;

const SongLocation = styled.div`
  display: flex;
  justify-content: right;
`;

const MeasurePrefix = styled(SliderLabel)`
  padding-right: 1px;
`;

const LocationSlider = styled.input<{$gradient: string}>`
  /* Should be shared with SliderBase, but styled component inheritance fails in this case - keep these in sync! */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: var(--color-slider);
  }
  &::-webkit-slider-runnable-track {
    background: linear-gradient(to right,
    ${props => props.$gradient}
    
    );
  }
`;
