import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {
  ChurchId,
  ClientChurch,
  Hymn,
  Hymnal,
  HymnalId,
  HymnalManifest,
  HymnalSlug,
  HymnalsManifest
} from '../../common/model';
import {churchHymnalIconPath, getHymnalIconPath, getSongSlug} from '../../common/paths';
import * as server_api from '../../common/server_api';
import {useLocalStorage} from './use_local_storage';
import {LocalStorageKey} from './client_local_storage';
import {useChurch} from './use_church';
import {getHymnFromSlug} from '../util/path';
import {CustomMusic, useCustomMusic} from './use_custom_music';
import {userVisibleSongNumber} from '../../common/util';

export const PrimaryChurchHymnalId = Number.MAX_SAFE_INTEGER;
export const PrimaryChurchHymnalSlug = 'PRIMARY';
export const PrimaryChurchHymnalName = 'Our Hymnal';

export const HymnalsWithIconBorder = ['Tanglewood Hymnal'];
const ChurchHymnalIconThresholdPercentage = 75;

export interface HymnalWithHymns extends Hymnal {
  hymns: Hymn[];
  message?: JSX.Element;
  icon?: HymnalIcon;
  hideTitle?: boolean;
}

export interface HymnalIcon {
  path: string;
  hasBorder?: boolean;
}

export function useHymnals(): HymnalWithHymns[] {
  const {hymnals} = useContext(HymnalsContext);
  return hymnals;
}

const HymnalsContext = createContext<{
  hymnals: HymnalWithHymns[];
}>({hymnals: []});

// NOTES:
// - Called twice whenever any inputs change (not sure why)
// - Called a minimum of 6x (3 pairs) during boot -> initially, when manifest loads, when hymnals populated
// - Not called in steady state during page transitions

export const HymnalsProvider = ({children}: {children: ReactNode}) => {
  const customMusic = useCustomMusic();
  const [hymnals, setHymnals] = useState<HymnalWithHymns[]>([]);
  const [hymnalsManifest, setHymnalsManifest] = useState<HymnalsManifest>({});
  const [hymnalsHash] = useLocalStorage(LocalStorageKey.HymnalsHash);
  const {church} = useChurch();
  const [weeklySongList] = useLocalStorage(LocalStorageKey.WeeklySongList); // for church hymnal invalidation

  useEffect(() => {
    if (!hymnalsHash) {
      return;
    }
    (async () => {
      try {
        const serverManifest = await server_api.getHymnalsManifest();
        setHymnalsManifest(serverManifest);
      } catch {
        console.error(`Unable to retrieve global hymnals manifest`);
      }
    })();
  }, [hymnalsHash]);

  useEffect(() => {
    if (!hymnalsManifest) {
      return;
    }
    (async () => {
      try {
        const populatedHymnals = await getHymnalsFromHymnalsManifest(hymnalsManifest);
        if (church) {
          populatedHymnals.unshift(await getChurchHymnal(church, populatedHymnals, customMusic));
        }
        setHymnals(prioritizeChurchHymnals(church, populatedHymnals));
      } catch (error: any) {
        // TODO(hewitt): Retry strategy?  Perhaps when we implement React Router data sources.
        console.error(`Failed to fetch hymnals: ${error.message}`);
      }
    })();
  }, [church, hymnalsManifest, customMusic, weeklySongList]);

  return (
    <HymnalsContext.Provider value={{hymnals}}>
      {children}
    </HymnalsContext.Provider>
  );
}


/********************************** hymnals **********************************/

function prioritizeChurchHymnals(church: ClientChurch | undefined, hymnals: HymnalWithHymns[]) {
  const churchHymnalIds = church?.hymnals?.map(hymnal => hymnal.id);
  if (!churchHymnalIds || hymnals.length === 0) {
    return hymnals;
  }
  let churchHymnal = hymnals.find(hymnal => hymnal.id === PrimaryChurchHymnalId);
  const primaryHymnal = church?.primaryHymnalId &&
    hymnals.find(hymnal => hymnal.id === church.primaryHymnalId);
  const nonPrimaryHymnals = hymnals.filter(hymnal =>
    hymnal.id !== PrimaryChurchHymnalId && hymnal.id !== church?.primaryHymnalId);
  const secondaryChurchHymnals = nonPrimaryHymnals.filter(hymnal => churchHymnalIds.includes(hymnal.id));
  const otherHymnals = nonPrimaryHymnals.filter(hymnal => !churchHymnalIds.includes(hymnal.id));
  return [
    ...(churchHymnal ? [churchHymnal] : []),
    ...(primaryHymnal ? [primaryHymnal] : []),
    ...secondaryChurchHymnals,
    ...otherHymnals,
  ]
}

async function getChurchHymnal(
  church: ClientChurch,
  hymnals: HymnalWithHymns[],
  customMusic: CustomMusic,
) {
  const churchHymnal = await server_api.getChurchHymnal(church.id);
  const primaryHymnal = church?.primaryHymnalId &&
    hymnals.find(hymnal => hymnal.id === church.primaryHymnalId);
  let hymns = churchHymnal.songs
    .map(song => getHymnFromSlug({songSlug: song.slug, hymnals, customMusic}))
    .filter(hymn => !!hymn) as Hymn[];
  if (primaryHymnal) {
    const hymnsNotInPrimaryHymnal = hymns.filter(hymn => hymn.hymnal !== primaryHymnal.name);
    hymns = [
      ...hymnsNotInPrimaryHymnal,
      ...primaryHymnal.hymns,
    ].map(hymn => ({...hymn}));  // new object to distinguish from hymn original hymnal
  }

  // sort custom songs to top, ordered alphabetically, non-custom songs to bottom, ordered by song number
  hymns.sort((lhs, rhs) => {
    if (lhs.basePath) {
      if (rhs.basePath) {
        return lhs.title.localeCompare(rhs.title);
      }
      return 0 - rhs.number;
    }
    if (rhs.basePath) {
      return lhs.number /*- 0*/;
    }
    return lhs.number - rhs.number;
  });

  return {
    id: PrimaryChurchHymnalId,
    name: PrimaryChurchHymnalName,
    slug: PrimaryChurchHymnalSlug,
    icon: getChurchHymnalIcon(hymnals, hymns),
    hymns,
  };
}

type ChurchHymnalStats = Map<HymnalId, number>; // count of songs per hymnal that are referenced by the church hymnal

function getChurchHymnalIcon(hymnals: HymnalWithHymns[], churchHymns: Hymn[]): HymnalIcon | undefined {
  const stats = getChurchHymnalStats(hymnals, churchHymns);
  for (const [hymnalId, count] of stats.entries()) {
    if ((count / churchHymns.length) * 100 >= ChurchHymnalIconThresholdPercentage) {
      return hymnals.find(({id}) => id === hymnalId)?.icon;
    }
  }
  return {path: churchHymnalIconPath};
}

function getChurchHymnalStats(hymnals: HymnalWithHymns[], churchHymns: Hymn[]): ChurchHymnalStats {
  // count of songs per hymnal that are referenced by the church hymnal
  const stats = new Map<HymnalId, number>();
  for (const hymn of churchHymns) {
    const hymnal = hymnals.find(({name}) => name === hymn.hymnal);
    if (!hymnal) {
      continue;
    }
    const id = Number(hymnal.id);
    const count = stats.get(id) ?? 0;
    stats.set(id, count + 1);
  }
  return stats;
}

export async function getHymnalsFromHymnalsManifest(hymnalsManifest: HymnalsManifest) {
  const hymnalNames = Object.keys(hymnalsManifest);
  const hymnalManifests: HymnalManifest[] = await Promise.all(
    hymnalNames.map(name => server_api.getHymnalManifest(name))
  );
  const populatedHymnals: HymnalWithHymns[] = hymnalManifests.map((hymnalManifest, index) => {
    const hasSuffix = (number: string) => Boolean(number.match(/\d+\.\d+/));
    const songNumbers = countSongNumbers(hymnalManifest);
    const [name, entry] = Object.entries(hymnalsManifest)[index];
    return {
      name,
      icon: {path: getHymnalIconPath(name), ...(HymnalsWithIconBorder.includes(name) && {hasBorder: true})},
      ...entry,
      hymns: getHymnsFromHymnalManifest({
        hymnalName: name,
        hymnalSlug: entry.slug,
        hymnalManifest,
        // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
        // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
        filter: (number: string) => hasSuffix(number) || songNumbers[number] <= 2,
      })
    };
  });
  return populatedHymnals;
}

function countSongNumbers(hymnalManifest: HymnalManifest) {
  return Object.keys(hymnalManifest)
    .map(key => key.match(/^\d+/)?.[0])
    .filter(key => key !== undefined)
    .reduce((prev, cur) => {
      // @ts-ignore (TS not yet smart enough to apply the filter)
      prev[cur] = (prev[cur] || 0) + 1;
      return prev;
    }, {} as {[number: string]: number});
}

export function getHymnsFromHymnalManifest(
  {hymnalName, hymnalSlug, churchId, hymnalManifest, filter, basePath}: {
    hymnalName: string;
    hymnalSlug?: HymnalSlug;
    churchId?: ChurchId;
    hymnalManifest: HymnalManifest;
    filter?: (number: string) => boolean;
    basePath?: string;
  }
): Hymn[] {
  return Object.entries(hymnalManifest)
    // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
    // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
    .filter(([number]) => !filter || filter(number))
    .map(([number, data]) => ({
      ...data,
      slug: getSongSlug({songNumber: userVisibleSongNumber(Number(number)), hymnalSlug, churchId}),
      number: Number(number),
      hymnal: hymnalName,
      ...(basePath && {basePath}),
    }))
    .sort((lhs, rhs) => lhs.number - rhs.number)
}

export function getSongSlugForHymn(
  hymnals: HymnalWithHymns[],
  hymn: {number: number, hymnal: string, basePath?: string},
): string | undefined {
  const songNumber = userVisibleSongNumber(hymn.number);
  let hymnalSlug: HymnalSlug | undefined;
  let churchId: string | undefined;
  if (hymn.basePath) {
    churchId = hymn.hymnal.match(/^(?<churchId>\d+)/)?.groups?.churchId;
    if (!churchId) {
      console.log(`ERROR: Cannot parse church Id from hymnal "${hymn.hymnal}" for hymn with basePath ${hymn.basePath}`);
      return undefined;
    }
  } else {
    hymnalSlug = hymnals.find(hymnal => hymnal.name === hymn.hymnal)?.slug;
    if (!hymnalSlug) {
      return undefined;
    }
  }
  return getSongSlug({songNumber, hymnalSlug, churchId: churchId ? Number(churchId) : undefined});
}
