import {useCallback} from 'react';
import styled from 'styled-components/macro';
import {PlainLinkOutlined} from '../util/shared';

export const PrivacyPage = () => {
  const onBack = useCallback(() => {
    window.history.back();
  }, []);
  return (
    <PrivacyScrollable>
      <PrivacyContent>
        <h1>Our Privacy Policy</h1>
        <div className='privacy-policy-content'>
          We are committed to giving you the best Sing Your Part experience possible!
          To do this, we collect and use some user data. Here is a simple summary of what data we collect and how we
          use it.<br/><br/>
          <h2>Information You Provide Us</h2>
          Upon logging in with Google or Apple, you provide us with your name and email address. We use these to send
          you
          email updates, which you can opt out of.<br/><br/>
          When subscribing, you have the option to provide your family last name, household email, and the church you
          attend. While this information is optional for subscribing,
          it is required if you wish to view your church's song list. We use this information to associate your
          household
          with a church and also
          to contact you with information about Sing Your Part.<br/><br/>
          <h2>Information We Automatically Collect</h2>
          We collect some data on how you use Sing Your Part (for example, song views or your church selection). We
          use this
          data for internal reseaarch purposes.
          We don't share your personal data with anybody. If you want to learn more, all the legalese is <a
          href='https://www.privacypolicies.com/live/475b40dc-5550-4b45-b6cd-9ff544f86f79'>here</a>. Or, email us
          at <a
          href='mailto:business@crescendosw.com'>business@crescendosw.com</a>.
        </div>
      </PrivacyContent>
      <PlainLinkOutlined onClick={onBack}>Return to previous page</PlainLinkOutlined>
    </PrivacyScrollable>
  );
}

const PrivacyScrollable = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;

const PrivacyContent = styled.div`
  text-align: left;
  padding: 5%;
  margin-bottom: 20px;
`;
