import '../util/shared.css';
import {HymnsList, LibraryPageTitle} from "./hymns_list";
import {useHymnals, PrimaryChurchHymnalId} from '../data/use_hymnals';

export const LibraryPage = () => {
  const hymnals = useHymnals();
  const libraryHymnals = hymnals
    .filter(hymnal => hymnal.id !== PrimaryChurchHymnalId)
    .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
  return <HymnsList hymnals={libraryHymnals} title={LibraryPageTitle}  isCurrentHymnalSticky={true} />;
};
