import {Church} from '../../common/model';
import {useEffect, useState} from 'react';
import * as server_api from '../../common/server_api';

export function useChurches(): Church[] {
  const [churches, setChurches] = useState<Church[]>([]);

  useEffect(() => {
    (async () => {
      setChurches(await server_api.getChurches());
    })();
  }, []);

  return churches;
}
