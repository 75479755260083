import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {getHymnsFromHymnalManifest} from './use_hymnals';
import {customMusicDir, getChurchCustomMusicManifestPath, musicManifestFilename} from '../../common/paths';
import {CustomMusicManifest, getOrgId, Hymn, HymnalManifest} from '../../common/model';
import {useLocalStorage} from './use_local_storage';
import {LocalStorageKey} from './client_local_storage';

export type CustomMusic = { [orgId: string]: Hymn[] };

const customMusicManifestPath = `/${customMusicDir}/${musicManifestFilename}`;

export function useCustomMusic(): CustomMusic {
  const {customMusic} = useContext(CustomMusicContext);
  return customMusic;
}

const CustomMusicContext = createContext<{
  customMusic: CustomMusic;
}>({customMusic: {}});

export const CustomMusicProvider = ({children}: {children: ReactNode}) => {
  const [customMusic, setCustomMusic] = useState<CustomMusic>({});
  const [customMusicManifest, setCustomMusicManifest] = useState<CustomMusicManifest | undefined>();
  const [customMusicHash] = useLocalStorage(LocalStorageKey.CustomMusicHash);

  useEffect(() => {
    if (!customMusicHash) {
      return;
    }
    void (async () => {
      setCustomMusicManifest(await fetch(customMusicManifestPath).then(res => res.json()));
    })();
  }, [customMusicHash]);

  useEffect(() => {
    (async () => {
      try {
        if (!customMusicManifest) {
          return;
        }
        const churchManifests: HymnalManifest[] = await Promise.all(Object.keys(customMusicManifest)
          .map(key => fetch(getChurchCustomMusicManifestPath(key)).then(res => res.json())));
        const hymnLists = Array.from(Object.keys(customMusicManifest).entries())
          .map(([index, key]) => [
            getOrgId(key),
            getHymnsFromHymnalManifest({
              hymnalName: key,
              churchId: getOrgId(key),
              hymnalManifest: churchManifests[index],
              basePath: `/${customMusicDir}`
            }),
          ]);
        setCustomMusic(Object.fromEntries(hymnLists));
      } catch (err: any) {
        // TODO(hewitt): Retry strategy?  Perhaps when we implement React Router data sources.
        console.error(`Failed to download custom music: ${err}`);
      }
    })();
  }, [customMusicManifest]);

  return (
    <CustomMusicContext.Provider value={{customMusic}}>
      {children}
    </CustomMusicContext.Provider>
  );
}
