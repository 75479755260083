import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Message, navigateToOnboardingPage} from './common';
import {Button} from './button';
import {Frame, navigateBack} from './frame';
import {OnboardingPages, Pages} from '../../../common/pages';
import {getUrlForPage} from '../../util/path';
import {useChurch} from '../../data/use_church';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import {uploadChurchCustomMusicFile} from '../../../common/server_api';
import {alert, confirm} from '../../util/confirm';
import {AppContext} from './app_context';
import {Spinner} from '../../util/spinner';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {useCustomMusic} from '../../data/use_custom_music';
import {Hymn} from '../../../common/model';

let fileToUpload: File | undefined; // TODO(hewitt): find a better way to pass this object (how would react router do this)

function setFileToUpload(file: File | undefined) {
  fileToUpload = file;
}

// https://www.freecodecamp.org/news/formdata-explained/
// "dropzone" is a good search keyword: https://www.npmjs.com/package/react-dropzone-uploader
// see https://medium.com/@blessingmba3/building-a-file-uploader-with-react-11dba6409480

async function validateFile(file: File): Promise<boolean> {
  if (file.name.toLowerCase().endsWith('.pdf')) {
    return true;
  }
  await alert({confirmation: <span>We currently only support PDF files.</span>});
  return false;
}

export async function beginFileUpload(navigate: NavigateFunction, file: File) {
  if (!await validateFile(file)) {
    setFileToUpload(undefined);
    return;
  }
  setFileToUpload(file);
  navigateToOnboardingPage(navigate, OnboardingPages.CustomMusicUpload);
}

enum UploadStatus {
  Before = 'before',
  Uploading = 'uploading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

// The setStatus guard is ineffective b/c react invokes the useEffect twice without updating status.
// So, we resort to a global boolean... :(
let busyUploading = false;

export const CustomMusicUpload = () => {
  const [status, setStatus] = useState<UploadStatus>(UploadStatus.Before);
  const [songName, setSongName] = useLocalStorage(LocalStorageKey.MusicUploadSongName);
  const [psalmNumber, setPsalmNumber] = useLocalStorage(LocalStorageKey.MusicUploadPsalmNumber);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const appContext = useContext(AppContext);
  const {church} = useChurch();
  const navigate = useNavigate();
  const customMusic = useCustomMusic();

  useEffect(() => {
    (async () => {
      if (busyUploading) {
        return;
      }
      busyUploading = true;
      try {
        if (status !== UploadStatus.Before || !fileToUpload || !church || !songName) {
          return;
        }
        setStatus(UploadStatus.Uploading);
        try {
          const songNumber = songName && await songNumberToOverwrite(customMusic[church.id], songName, psalmNumber);
          await uploadChurchCustomMusicFile({
            churchId: church.id,
            songName,
            psalmNumber,
            file: fileToUpload,
            songNumber,
          });
          setSongName(undefined);
          setPsalmNumber(undefined);
          setStatus(UploadStatus.Succeeded);
        } catch (error: any) {
          setErrorMessage(error.message);
          setStatus(UploadStatus.Failed);
        }
        setFileToUpload(undefined);
      } finally {
        busyUploading = false;
      }
    })();
  }, [church, customMusic, songName, psalmNumber, status, setPsalmNumber, setSongName]);

  const onRetry = useCallback(() => {
    navigateBack(appContext);
    setTimeout(() => appContext?.setPageAnimation('moveToLeftFromRight'), 700);
  }, [appContext]);

  const onContinue = useCallback(() => {
    navigate(getUrlForPage(Pages.Organization)); // TODO(hewitt): should open "Our Hymnal" on the org page
  }, [navigate]);

  return (
    <Frame
      onboardingPage={OnboardingPages.CustomMusicUpload}
      mainContent={
        status === UploadStatus.Before || status === UploadStatus.Uploading ? (
          <>
            <Message $widthInChars={16}>
              Uploading file
            </Message>
            <Spinner />
          </>
        ) : status === UploadStatus.Failed ? (
          <Message $widthInChars={24}>
            File upload failed.<br/><br/>
            {errorMessage}
          </Message>
        ) : (
          <Message $widthInChars={24}>
            File upload successful!<br/><br/>
            The new song will be visible in your church hymnal within a couple of minutes.
          </Message>
        )
      }
      footerContent={
        status === UploadStatus.Before || status === UploadStatus.Uploading ? (
          <Button onClick={onContinue}>CANCEL</Button>
        ) : status === UploadStatus.Failed ? (
          <>
            <Button onClick={onRetry}>RETRY</Button>
            <Button onClick={onContinue}>CANCEL</Button>
          </>
        ) : (
          <Button onClick={onContinue}>DONE</Button>
        )
    }
      onEnterKeypress={onContinue}
    />
  );
};

async function songNumberToOverwrite(
  customMusic: Hymn[], songName: string, psalmNumber?: number
): Promise<string | undefined> {

  if (!customMusic) {
    return undefined;
  }

  for (const hymn of customMusic) {
    const hymnPsalmNumber = hymn.psalm?.match(/(?<number>\d+)/)?.groups?.number;
    if (
      hymn.title.toLowerCase() === songName.toLowerCase() && !!hymn.psalm === !!psalmNumber &&
      (!psalmNumber || psalmNumber === Number(hymnPsalmNumber))
    ) {
      if (await confirm({
        confirmation: <span>Would you like to overwrite the existing song with the same name?</span>
      })) {
        return hymn.number.toString().padStart(3, '0');
      }
      break;
    }
  }
}
