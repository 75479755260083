import React, {useCallback} from 'react';
import "./kebab.css"
import {ReactComponent as InfoIcon} from "../assets/info-outline.svg";
import {ReactComponent as OptionsIcon} from "../assets/options.svg";
import {ReactComponent as PdfIcon} from "../assets/document-text-outline.svg";
import {ReactComponent as ReportIssueIcon} from "../assets/tools.svg";
import styled from 'styled-components/macro';


interface Props {
  displaySongOptions: () => void;
  displaySongInfo: () => void;
  displayPdf: () => void;
  displayModal: () => void;
  close: () => void;
}

export const Kebab = (props: Props) => {
  const onReportIssue = useCallback(() => {
    props.close()
    props.displayModal()
  }, [props]);

  function renderKebab() {
    return (
      <KebabWrapper>
        {[
          {label: 'Song Information', icon: <InfoIcon className="icon"/>, onClick: props.displaySongInfo},
          {label: 'Open Sheet Music', icon: <PdfIcon className="icon"/>, onClick: props.displayPdf},
          {label: 'Playback Settings', icon: <OptionsIcon className="icon"/>, onClick: props.displaySongOptions},
          {
            label: 'Report Issue',
            icon: <div className='icon'>
              <ReportIssueIcon style={{transform: "scale(.75)"}}/>
            </div>,
            onClick: onReportIssue
          },
        ].map(({label, icon, onClick}) =>
          <div key={label} className="navOption" onClick={() => onClick()}>
            <IconWrapper>
              {icon}
            </IconWrapper>
            <div className="optionText">
              {label}
            </div>
          </div>
        )}
      </KebabWrapper>
    )
  }

  return (
    <div>
      {renderKebab()}
    </div>
  )
};

const KebabWrapper = styled.div`
  width: 100vw;
  height: 100%;
`;

const IconWrapper = styled.div`
  padding-left: 15px;
  padding-right: 10px;
`;