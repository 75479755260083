import styled from 'styled-components/macro';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {SongCell} from './song_cell';
import {SongList, SongListType} from '../../../../common/model';
import {CellPosition} from './liturgy_planner';
import {AllSongLists, useAllSongLists} from './use_all_song_lists';
import {dateFromString} from '../../../../common/date_string';
import {synchronizeAllSongLists} from '../../../data/use_church';
import {getPlatform, Platform} from '../../../util/platform';
import {useCustomMusic} from '../../../data/use_custom_music';
import {useHymnals} from '../../../data/use_hymnals';

export interface HymnOfTheMonthPlannerProps {
  songList: SongList;
  onClose: () => void;
}

export const HymnOfTheMonthPlanner = ({songList, onClose}: HymnOfTheMonthPlannerProps) => {

  function generateHymnsOfTheMonth(allSongLists: AllSongLists): SongList[] {
    const currentHymnsOfTheMonth = allSongLists.filter(songList => songList.type === SongListType.HymnOfTheMonth);
    const year = dateFromString(songList.date).getFullYear().toString();
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    let hymnsOfTheMonth: SongList[] = [];

    for (const index in months) {
      const hymnForMonth = currentHymnsOfTheMonth.find(hymn => dateFromString(hymn.date).getMonth() + 1 === Number(months[index]));
      if(!hymnForMonth) {
        hymnsOfTheMonth[index] = {
          type: SongListType.HymnOfTheMonth,
          date: `${year}-${months[index]}-01`,
          songs: []
        }
      }
      else {
        hymnsOfTheMonth[index] = hymnForMonth;
      }
    }
    return hymnsOfTheMonth;
  }

  const dialogElement = useRef<HTMLDialogElement>(null);
  const [selectedCell, setSelectedCell] = useState<CellPosition>({date: songList.date, row: 1});
  const allSongLists = useAllSongLists();
  const hymnsOfTheMonth = generateHymnsOfTheMonth(allSongLists);
  const customMusic = useCustomMusic();
  const hymnals = useHymnals();

  const onCloseDialog = useCallback(async () => {
    void synchronizeAllSongLists();
    onClose();
  }, [onClose]);

  const onClickBackground = useCallback(async (event: MouseEvent) => {
    if (event.target === dialogElement.current) {
      await onCloseDialog();
    }
  }, [onCloseDialog]);

  useEffect(() => {
    const {current} = dialogElement;
    current?.showModal();
    current?.addEventListener('click', onClickBackground);
    return () => {current?.removeEventListener('click', onClickBackground)}
  });

  const getTitle = () => {
    const year = `${dateFromString(songList.date).getFullYear()}`;
    const onMobile = getPlatform() === Platform.Mobile;
    return onMobile ? year : `${year} Hymns of the Month`;
  }

  return (
    <Dialog ref={dialogElement}>
      <DialogContent>
        <YearTitle>{getTitle()}</YearTitle>
        <CloseButton key='close' onClick={onCloseDialog}>Done</CloseButton>
          <ScrollWrapper key='scroll-wrapper'>
            <SongCellsWrapper>
              {
                hymnsOfTheMonth.map((hymn, index) => {
                  return (
                    <SongCell
                      key={index}
                      column={1}
                      date={hymn.date}
                      row={1}
                      songList={hymn}
                      songListType={SongListType.HymnOfTheMonth}
                      selectedCell={selectedCell}
                      setSelectedCell={setSelectedCell}
                      isInHymnOfTheMonthPlanner={true}
                      customMusic={customMusic}
                      hymnals={hymnals}
                    />
                  )
                })
              }
            </SongCellsWrapper>
        </ScrollWrapper>
      </DialogContent>
    </Dialog>
  );
}

const Dialog = styled.dialog`
  height: 80vh;
  overflow: hidden;
  padding: 0; // prevent clicks on dialog itself
  margin: auto;
  border-radius: 15px;
  border: none;
  overscroll-behavior-x: none; /* avoid horizontal dialog scroll on iOS */
`;

// prevents clicks on dialog itself
const DialogContent = styled.div`
  height: 80vh;
  padding: 20px;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const YearTitle = styled.p`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: min(max(1.5vw, 1.1rem), 2rem);
  border: none;
  text-align: center;
  color: var(--color-text);
  background-color: transparent;
  padding: 0 20px 20px 20px;
  width: 65vw;
  text-overflow: ellipsis;
`;

const ScrollWrapper = styled.div`
  height: 62vh;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior-x: none; /* avoid swipe to go back */
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px;
  margin: 8px 7px;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 0.75rem;
  color: var(--color-background);
  background-color: var(--color-primary-button);
  border-radius: 20px;
  cursor: pointer;
`;

const SongCellsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1px; /* avoids cell outline over-reach */
  padding: 3px; /* allows left & right cell outlines to be visible*/
`;
