import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {EngineeringAdminWrapper} from './shared';
import {Pages} from '../../../../common/pages';
import {getUrlForPage} from '../../../util/path';

export const EngineeringPage = () => {
  return (
    <EngineeringAdminWrapper title={'Engineering'}>
      <LinkList>
        <LinkWrapper key={Pages.EngineeringCustomMusic}><Link to={getUrlForPage(Pages.EngineeringCustomMusic)}>Custom Music</Link></LinkWrapper>
        <LinkWrapper key={Pages.EngineeringBrokenSongs}><Link to={getUrlForPage(Pages.EngineeringBrokenSongs)}>Broken Songs</Link></LinkWrapper>
      </LinkList>
    </EngineeringAdminWrapper>
  );
}

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

const LinkWrapper = styled.div`
  font-size: 1.3rem;
`;
