import React, {useCallback, useEffect, useState} from 'react';
import {EngineeringAdminWrapper, useRefreshMusicCaches} from '../shared';
import {useBrokenSongsForThisSunday} from './shared';
import styled from 'styled-components/macro';
import {getPdfUrlForHymn} from '../../../../util/path';
import {Spinner} from '../../../../util/spinner';
import {useCustomMusic} from '../../../../data/use_custom_music';
import {isFileNewer} from '../../../../../common/server_api';
import {EngineeringSongList, getSongId, PDFLink} from './engineering_song_list';
import {SongSlug} from '../../../../../common/model';
import {UploadButton} from './upload_button';

export const EngineeringCustomMusicPage = () => {
  const customMusic = useCustomMusic();
  const [isPdfFileNewerThanXmlFile, setIsPdfFileNewerThanXmlFile] = useState<{[songId: string]: boolean}>({});
  const {brokenSongsByOrg} = useBrokenSongsForThisSunday();
  useRefreshMusicCaches();

  useEffect(() => {
    if (!customMusic) {
      return;
    }
    void (async () => {
      const allMusicXMLDownloadResults = await Promise.all(
        Object.entries(customMusic).map(([orgId, hymns]) =>
          hymns.map(async (hymn) => {
            const pdfPath = getPdfUrlForHymn(hymn);
            const xmlPath = pdfPath.replace('.pdf', '.xml');
            const response = await isFileNewer(pdfPath, xmlPath);
            return [getSongId(orgId, hymn), response];
          })
        ).flat(1)
      );
      setIsPdfFileNewerThanXmlFile(Object.fromEntries(allMusicXMLDownloadResults));
    })();
  }, [customMusic]);

  const showPDFLink = useCallback((songId: string) => {
    return isPdfFileNewerThanXmlFile[songId];
  }, [isPdfFileNewerThanXmlFile]);

  const isThisSunday = useCallback((songSlug: SongSlug) => {
    return Boolean(Object.values(brokenSongsByOrg).find((hymns) => hymns.find(({slug}) => slug === songSlug)));
  }, [brokenSongsByOrg]);

  return (
    <EngineeringAdminWrapper title={'Custom Music'}>
      <OuterWrapper>
        <UploadButton/>
        <Content key='content'>
          Churches colored <TestChurch>blue</TestChurch> are test churches, ignore them. <br/>
          Songs with a <PDFLink $visible={true} $highlight={true}>PDF link</PDFLink> do not yet have MusicXML files:
        </Content>
        {
          Object.keys(isPdfFileNewerThanXmlFile).length === 0
            ? <Spinner key='spinner'/>
            : <EngineeringSongList
              key='song-list'
              hymnsByOrg={customMusic}
              showPDFLink={showPDFLink}
              isThisSunday={isThisSunday} />
        }
      </OuterWrapper>
    </EngineeringAdminWrapper>
  );
}

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 20px;
`;

const Content = styled.div`
  margin: 20px 0;
`;

export const TestChurch = styled.p`
  color: deepskyblue;
  display: inline;
`
