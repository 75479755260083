import {Hymn, SongSlug} from '../../../../../common/model';
import {useChurches} from '../../../../data/use_churches';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {generateFileName, generateHymnUrl, getPdfUrlForHymn, getTextUrlForHymn} from '../../../../util/path';
import {TestChurch} from './engineering_custom_music_page';
import {testChurchIds} from '../../../../data/use_church';
import styled from 'styled-components';

export interface HymnsByOrg {
  [orgId: string]: Hymn[]
}

export function EngineeringSongList({hymnsByOrg, isThisSunday, openPDFInBrowser, showPDFLink, showTXTLink}: {
  hymnsByOrg: HymnsByOrg;
  isThisSunday?: (songSlug: SongSlug) => boolean;
  openPDFInBrowser?: boolean;
  showPDFLink?: (songId: string) => boolean;
  showTXTLink?: boolean;
}) {
  const churches = useChurches();

  const getChurchName = (orgId: string) => {
    const church = churches.find(church => church.id === Number(orgId));
    if (church) {
      return testChurchIds.includes(church.id) ? <TestChurch>{church.name}</TestChurch> : church.name
    }
  }

  return (
    <SongListWrapper $showTXTLink={showTXTLink}>
      {
        !hymnsByOrg ? null :
          Object.entries(hymnsByOrg).map(([orgId, hymns]) =>
            hymns
              .map((hymn) => {
                const songId = getSongId(orgId, hymn);
                return (
                  <Fragment key={songId}>
                    <div key='org-id'>{getChurchName(orgId)}</div>
                    <div key='song-id'>{songId}</div>
                    <Link key='hymn-url' to={generateHymnUrl({hymn, suppressSongIssue: true})}>
                      {hymn.title}
                    </Link>
                    <PDFLink
                      key='pdf'
                      href={getPdfUrlForHymn(hymn)}
                      download={openPDFInBrowser ? undefined : `${orgId}-${generateFileName(hymn)}`}
                      $visible={!showPDFLink || showPDFLink(songId)}
                      $highlight={!!showPDFLink}
                      target={openPDFInBrowser ? '_blank' : ''}
                    >
                      PDF {isThisSunday?.(hymn.slug) ? '(This Sunday)'  : ''}
                    </PDFLink>
                    {
                      showTXTLink &&
                      <TXTLink key='txt' href={getTextUrlForHymn(hymn)} target='_blank'>TXT</TXTLink>
                    }
                  </Fragment>
                );
              }))
      }
    </SongListWrapper>
  );
}

const SongListWrapper = styled.div<{$showTXTLink?: boolean}>`
  display: grid;
  grid-template-columns: 1fr 10ch 1fr 15ch ${props => props.$showTXTLink ? '10ch' : ''};
  justify-items: left;
`;

export function getSongId(orgId: string, hymn: Hymn) {
  return `${orgId}-${hymn.number}`;
}

export const PDFLink = styled.a<{$visible: boolean, $highlight: boolean}>`
  color: ${props => props.$highlight ? 'var(--color-pdf-upload)' : 'var(--color-text)'};
  ${props => props.$visible ? '' : 'visibility: hidden;'}
`;

const TXTLink = styled.a`
  color: var(--color-pdf-upload);
`;
