import React, {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  PointerEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components/macro';
import {Hymn, SongList, SongListType, SongSlug} from '../../../../common/model';
import {HymnalEntryPadding, HymnRowHeight, HymnsList} from '../../hymns_list';
import {getPlatform, Platform} from '../../../util/platform';
import {PdfViewerPage} from '../../pdf_viewer_page';
import {ReactComponent as PlayIcon} from '../../../assets/play-circle.svg';
import {ReactComponent as PauseIcon} from '../../../assets/pause-circle.svg';
import {useSequencer} from '../../../player/use_sequencer';
import {getMidiUrlForHymn} from '../../../util/path';
import {useHymnals} from '../../../data/use_hymnals';
import {alert} from '../../../util/confirm';
import {QuarterNote} from '../../../util/shared';
import {setSongTempoForChurch, useChurch} from '../../../data/use_church';

export interface SongSelectorProps {
  initialValue?: string;
  onClose: () => void;
  onSongClick: (songSlug?: SongSlug) => void;
  songList: SongList;
  selectSearchValue?: boolean;
}

const PlayButtonDiameter = `calc(1.5 * ${HymnRowHeight})`;

export const SongSelector = ({initialValue, onClose, onSongClick, songList, selectSearchValue}: SongSelectorProps) => {
  const dialog = useRef<HTMLDialogElement>(null);
  const [hymnToPreview, setHymnToPreview] = useState<Hymn | undefined>();
  const hymnals = useHymnals();
  const playButtonRef = useRef<HTMLButtonElement>(null);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const onReadyToPlay = useCallback(() => setReadyToPlay(true), []);
  const [isPlaying, setIsPlaying] = useState(false);

  const {sequencer, defaultTempo, setCurrentTempo} = useSequencer({
    hymns: hymnToPreview ? [hymnToPreview] : [],
    onReadyToPlay,
  });

  useEffect(() => {
    setReadyToPlay(false);
    setIsPlaying(false);
  }, [hymnToPreview]);

  // TODO(hewitt): set voice values to default
  //       voiceValues: getDefaultValue(LocalStorageKey.VoiceValues),

  const onClickBackground = useCallback((event: MouseEvent) => {
    if (event.target === dialog.current) {
      onClose();
    }
  }, [onClose]);


  useEffect(() => {
    const {current} = dialog;
    current?.showModal();
    current?.addEventListener('click', onClickBackground);
    return () => {current?.removeEventListener('click', onClickBackground)}
  });

  const onPreviewHymn = useCallback((hymn: Hymn | undefined) => {
    if (hymn) {
      const {issue, ...hymnNoIssue} = hymn;
      if (JSON.stringify(hymnToPreview) !== JSON.stringify(hymnNoIssue)) {
        setHymnToPreview(hymnNoIssue);
      }
    } else {
      setHymnToPreview(undefined);
    }
  }, [hymnToPreview]);

  const play = useCallback(() => {
    setIsPlaying(true);
    sequencer.play();
  }, [sequencer]);

  const pause = useCallback(() => {
    setIsPlaying(false);
    sequencer.pause();
  }, [sequencer]);

  const playButton = useCallback((): React.ReactElement | null => {
    return !readyToPlay ? null : (
      <PlayButton
        key='play-button'
        hymnToPreview={hymnToPreview}
        play={play}
        pause={pause}
        isPlaying={isPlaying} // Note: sequencer.isPlaying does not properly invalidate with React, so use our own
        ref={playButtonRef}/>
    );
  }, [hymnToPreview, isPlaying, play, pause, readyToPlay]);

  const onSearchKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>, searchString?: string) => {
    if (event.key === 'ArrowRight' && (event.target as HTMLInputElement).selectionStart === searchString?.length) {
      event.stopPropagation();
      event.preventDefault();
      if (!readyToPlay) {
        return;
      }
      if (isPlaying) {
        pause();
      } else {
        play();
      }
    }
  }, [isPlaying, pause, play, readyToPlay]);

  const headerAfterFirstHymnal = <LibraryHeader>Library</LibraryHeader>

  return (
    <SongSelectorDialog ref={dialog}>
      <OuterWrapper>
        <HymnsListWrapper>
          <HymnsList
            hymnals={hymnals}
            title={'Song Selector'}
            activateSearch={true}
            onSongClick={onSongClick}
            onClose={onClose}
            defaultSearchString={initialValue}
            isInSongSelector={true}
            selectSearchValue={selectSearchValue}
            onPreviewHymn={onPreviewHymn}
            onSearchKeyDownProp={onSearchKeyDown}
            deleteButtonVisible={getPlatform() === Platform.Mobile || songList.type === SongListType.HymnOfTheMonth}
            playButton={playButton}
            headerAfterFirstHymnal={headerAfterFirstHymnal}
          />
        </HymnsListWrapper>
        {
          hymnToPreview &&
          <PreviewPane hymn={hymnToPreview} defaultTempo={defaultTempo} setCurrentTempo={setCurrentTempo} />
        }
      </OuterWrapper>
    </SongSelectorDialog>
  );
};

const previewPaneVisibilityBreakpoint = '900px';
const songSelectorHeight = '95vh';
const songSelectorVerticalPadding = '10px';

const SongSelectorDialog = styled.dialog`
  height: ${songSelectorHeight};
  width: 95vw;
  margin: auto;
  border-radius: 15px;
  background-color: var(--color-background);
  border: none;
  overflow: hidden;
  padding: ${songSelectorVerticalPadding} 20px;
  @media (max-width: ${previewPaneVisibilityBreakpoint}) {
    max-width: 500px;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
`;

const HymnsListWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

function PreviewPane({hymn, defaultTempo, setCurrentTempo}: {
  hymn: Hymn,
  defaultTempo: number | undefined;
  setCurrentTempo: (tempo: number) => void;
}) {
  const pdfWrapperRef = useRef<HTMLDivElement>(null);
  const [pdfWidth, setPdfWidth] = useState(pdfWrapperRef.current?.clientWidth ?? 0);
  const pdfHeight = `calc(${songSelectorHeight} - (2 * ${songSelectorVerticalPadding}));`;

  // properly resize PDF on window load & resize (`clientWidth` changes don't trigger React refresh)
  const onResize = useCallback(() => {
    const clientWidth = pdfWrapperRef.current?.clientWidth;
    setPdfWidth(clientWidth ?? 0);
  }, [pdfWrapperRef]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [onResize]);

  return (
    <PreviewPaneWrapper>
      <PdfWrapper ref={pdfWrapperRef}>
        <PdfViewerPage
          hymn={hymn}
          height={pdfHeight}
          width={pdfWidth}
          onFinishedLoading={onResize}
          isInSongSelector={true}
        />
      </PdfWrapper>
      <TempoAdjuster hymn={hymn} defaultTempo={defaultTempo} setCurrentTempo={setCurrentTempo}/>
    </PreviewPaneWrapper>
  );
}

const PreviewPaneWrapper = styled.div`
  position: relative;
  @media (max-width: ${previewPaneVisibilityBreakpoint}) {
    display: none;
  }
  flex: 2;
  padding-left: 20px;
`;

const PdfWrapper = styled.div`
`;

const PlayButton = forwardRef(({hymnToPreview, play, pause, isPlaying}: {
  hymnToPreview: Hymn | undefined;
  play: () => void;
  pause: () => void;
  isPlaying: boolean;
}, ref: any) => {
  const [missingMidiFile, setMissingMidiFile] = useState(false);

  useEffect(() => {
    setMissingMidiFile(false);
  }, [hymnToPreview]);

  const onPlayButtonClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (missingMidiFile) {
      void alert({confirmation: <span>This song has not yet been imported.</span>});
      return;
    }
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [missingMidiFile, play, pause, isPlaying]);

  const onMouseDown = useCallback((event: PointerEvent<HTMLButtonElement>) => {
    event.preventDefault(); // avoid stealing focus from search input
  }, []);

  useEffect(() => {
    void (async () => {
      if (!hymnToPreview) {
        return;
      }
      const response = await fetch(getMidiUrlForHymn(hymnToPreview));
      setMissingMidiFile(!response.ok)
    })();
  }, [hymnToPreview]);

  return (
    <PlayButtonWrapper ref={ref}>
      <PlayButtonRightBorderCover/>
      <PlayButtonSelectionBorder onClick={onPlayButtonClick} onMouseDown={onMouseDown}>
        {isPlaying ? <PauseIconWrapper/> : <PlayIconWrapper $isDisabled={missingMidiFile}/>}
        <WhiteCircleBackground/>
      </PlayButtonSelectionBorder>
    </PlayButtonWrapper>
  );
});

const PlayButtonWrapper = styled.div`
  z-index: 2;
`;

const PlayButtonSelectionBorder = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-selected-item);
  border-radius: 80px;
  fill: var(--color-text);
  border: none;
  cursor: pointer;
`;

const PlayIconWrapper = styled(PlayIcon)<{$isDisabled: boolean}>`
  z-index: 1;
  width: ${PlayButtonDiameter};
  fill: ${props => props.$isDisabled ? 'var(--color-background-button-disabled)' : 'var(--color-text)'};
`

const PauseIconWrapper = styled(PauseIcon)`
  z-index: 1;
  width: ${PlayButtonDiameter};
  fill: var(--color-text);
`

const LibraryHeader = styled.h1`
  font-family: Jost-SemiBold, Arial, sans-serif;
  padding-top: 0.7lh;
  padding-left: ${HymnalEntryPadding};
  color: var(--color-text);
`;

// Causes middle of play button image to be white/black instead of gray
const WhiteCircleBackground = styled.div`
  position: absolute;
  height: calc(${PlayButtonDiameter} / 3);
  width: calc(${PlayButtonDiameter} / 3);
  background-color: var(--color-background);
`;

const PlayButtonRightBorderCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: ${HymnRowHeight};
  width: calc(${PlayButtonDiameter} / 2);
  background-color: var(--color-background);
`;

function TempoAdjuster({hymn, defaultTempo, setCurrentTempo}: {
  hymn: Hymn;
  defaultTempo: number | undefined;
  setCurrentTempo: (tempo: number) => void;
}) {
  const {church} = useChurch();

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const songTempo = Number((event.target as HTMLInputElement).value);
    setCurrentTempo(songTempo);
    if (!church) {
      return;
    }
    void setSongTempoForChurch({church, songSlug: hymn.slug, songTempo});
  }, [church, hymn, setCurrentTempo]);

  return (
    <TempoWrapper>
      <QuarterNote width={'10px'}/>
      <TempoEquals>=</TempoEquals>
      <TempoValue
        type='number'
        value={defaultTempo}
        $charWidth={defaultTempo?.toString().length ?? 2}
        onChange={onChange}
      />
    </TempoWrapper>
  );
}

const TempoWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 65px;
  padding: 8px 20px;
  border-radius: 15px;
  background-color: color-mix(in srgb, var(--color-background) 85%, transparent);
  color: var(--color-text);
  &:hover {
    background-color: var(--color-background);
  }
  display: flex;
  gap: 5px;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.2rem;
`;

const TempoValue = styled.input<{$charWidth: number}>`
  width: calc(${props => props.$charWidth}ch + 1.5ch);
  text-align: center;
  border: none;
  padding: 0;
  &::-webkit-inner-spin-button {
    opacity: 1;
  }
`;

const TempoEquals = styled.div`
  padding-left: 0.3ch;
`;
