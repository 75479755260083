import {Favorite, Hymn} from "../../common/model";
import * as server_api from '../../common/server_api';
import {localStorageGet, LocalStorageKey, localStorageSet} from "./client_local_storage";
import {ensureExists} from "../../common/util";
import {HymnalWithHymns} from './use_hymnals';

class Favorites {

  add(hymn: Hymn) {
    const {server, added, removed} = _getLists();
    const favorite: Favorite = {hymnalName: ensureExists(hymn.hymnal), songNumber: hymn.number};

    if(_includes(removed, favorite)) {
      localStorageSet(LocalStorageKey.FavoritesRemoved, removed.filter(value => !_equal(value, favorite)));
    } else {
      if (_includes(added, favorite) || _includes(server, favorite)) {
        throw Error(`Already a favorite (cannot be added): ${favorite}`);
      }
      localStorageSet(LocalStorageKey.FavoritesAdded, [...added, favorite]);
    }
    void this.reconcileWithServer();
  }

  remove(hymn: Hymn) {
    const {server, added, removed} = _getLists();
    const favorite: Favorite = {hymnalName: ensureExists(hymn.hymnal), songNumber: hymn.number};

    if (_includes(added, favorite)) {
      localStorageSet(LocalStorageKey.FavoritesAdded, added.filter(value => !_equal(value, favorite)));
    } else {
      if (_includes(removed, favorite) || !_includes(server, favorite)) {
        throw Error(`Not yet a favorite (cannot be removed): ${favorite}`);
      }
      localStorageSet(LocalStorageKey.FavoritesRemoved, [...removed, favorite]);
    }
    void this.reconcileWithServer();
  }

  get(hymnals: HymnalWithHymns[]): Hymn[] {
    return this._currentFavorites()
      .map(({hymnalName, songNumber}) => hymnals
        .find(({name}) => name === hymnalName)?.hymns?.find(({number}) => number === songNumber))
      .filter(hymn => hymn !== undefined) as Hymn[];
  }

  async reconcileWithServer() {
    const {added, removed} = _getLists();
    for (const favorite of added) {
      await server_api.addFavorite(favorite);
    }
    for (const favorite of removed) {
      await server_api.removeFavorite(favorite);
    }
    localStorageSet(LocalStorageKey.Favorites, await server_api.getFavorites());
    localStorageSet(LocalStorageKey.FavoritesAdded, []);
    localStorageSet(LocalStorageKey.FavoritesRemoved, []);
    this.notifyCallbacks();
  }

  includes(hymn: Hymn): boolean {
    const favorite: Favorite = {hymnalName: ensureExists(hymn.hymnal), songNumber: hymn.number};
    return _includes(this._currentFavorites(), favorite);
  }

  registerCallback(callback: () => void): number {
    return this._callbacks.push(callback) - 1;
  }

  unregisterCallback(token: number) {
    this._callbacks.splice(token, 1);
  }

  notifyCallbacks() {
    for (const callback of this._callbacks) {
      callback();
    }
  }

  private _callbacks: Array<() => void> = [];

  private _currentFavorites(): Favorite[] {
    const {server, added, removed} = _getLists();

    return [
      ...server.filter(favorite => !_includes(removed, favorite)),
      ...added,
    ];
  }
}

function _getLists() {
  return {
    server: localStorageGet(LocalStorageKey.Favorites),
    added: localStorageGet(LocalStorageKey.FavoritesAdded),
    removed: localStorageGet(LocalStorageKey.FavoritesRemoved),
  };
}

function _includes(list: Favorite[], favorite: Favorite) {
  for (const current of list) {
    if (_equal(current, favorite)) {
      return true;
    }
  }
  return false;
}

function _equal(lhs: Favorite, rhs: Favorite) {
  return lhs.hymnalName === rhs.hymnalName && lhs.songNumber === rhs.songNumber;
}

export const favorites = new Favorites();
