import {useCallback} from 'react';
import {localStorageGet, LocalStorageKey, localStorageRemove, localStorageSet} from './client_local_storage';
import * as server_api from '../../common/server_api';
import {
  ClientChurch,
  getCurrentStartEndDatesForSchoolTerms,
  Household,
  isAdminForCurrentChurch,
  OrganizationType,
  SongList,
  SongListId,
  SongListType, SongSlug
} from '../../common/model';
import {useLocalStorage} from './use_local_storage';
import {OneDayMs} from '../../common/sleep';
import {
  addDayOffset,
  dateFromString,
  dateStringFromDate, maxDateAbsolute, minDate,
  minDateAbsolute, thisMonthDate,
  thisSundayDate,
  todayDate
} from '../../common/date_string';
import {
  clearAllSongListUpdates,
  DeletableSongList,
  getSongListUpdatesBefore,
  removeSongListUpdatesBefore,
  songListUpdatesPending
} from './song_updates';

export const testChurchIds = [5, 243];
export const songHistorySizeInWeeks = 52 * 2;
export const songFutureSizeInWeeks = 8;

// TODO(hewitt): Remove these special cases eventualy
// Note that 2024-2025 New Saint Andrews freshman have cached a "Church" with no type from the
// beginning of the school year - arguably we should not remove this hack until June 2025
// unless we retroactively update their cached "Church"
export const specialCaseSchools = ['The Ambrose School', 'New Saint Andrews'];

export function useChurch(): {
  church: ClientChurch | undefined
  setChurch: (church: ClientChurch | undefined) => void
} {
  const [church, setChurch] = useLocalStorage(LocalStorageKey.Church);

  const setChurchAndPushToServer = useCallback((newChurch: ClientChurch | undefined) => {

    if (JSON.stringify(church) === JSON.stringify(newChurch)) {
      return;
    }
    setChurch(newChurch);
    localStorageSet(LocalStorageKey.PushChurch, true);
    void synchronizeChurchWithServer();
  }, [church, setChurch]);

  return {church, setChurch: setChurchAndPushToServer};
}

function getHouseholdChurch(
  householdChurchId: number | undefined,
  householdChurchName: string | undefined
): ClientChurch | undefined {
  if (householdChurchId === undefined || householdChurchName === undefined) {
    return undefined;
  }
  const {name, location} = server_api.churchFromChurchString(householdChurchName);
  return {id: householdChurchId, name, location};
}

// syncs once per day unless forced, pushing, or the church is missing
export async function synchronizeChurchWithServer({force}: {force?: boolean} = {}) {
  // ignore network errors
  try {
    // TODO(hewitt): Remove 9/2024 - eradicate old church string cache entries (replace with Church object)
    if (typeof localStorageGet(LocalStorageKey.Church) === 'string') {
      localStorageRemove(LocalStorageKey.Church);
      localStorageRemove(LocalStorageKey.PushChurch);
    }

    const household = localStorageGet(LocalStorageKey.Household);
    if (!household?.token) {
      localStorageRemove(LocalStorageKey.Church);
      localStorageRemove(LocalStorageKey.PushChurch);
      return;
    }

    let church = localStorageGet(LocalStorageKey.Church);
    const pushChurch = localStorageGet(LocalStorageKey.PushChurch);

    if (!force && !pushChurch &&
      church?.mostRecentSyncTimestamp && (Date.now() - church.mostRecentSyncTimestamp) < OneDayMs
    ) {
      return;
    }

    // TODO(hewitt): Remove 9/2024 - Migrate from billing church id & church name to household church
    const householdChurchId = localStorageGet(LocalStorageKey.HouseholdChurchId);
    const householdChurchName = localStorageGet(LocalStorageKey.HouseholdChurchName);
    if (!church && householdChurchId) {
      church = getHouseholdChurch(householdChurchId, householdChurchName);
      localStorageSet(LocalStorageKey.Church, church);
    }
    localStorageRemove(LocalStorageKey.HouseholdChurchId);
    localStorageRemove(LocalStorageKey.HouseholdChurchName);

    if (pushChurch) {
      await server_api.setHouseholdChurch(household.token, church);
      const {churchId: _unused, ...householdWithoutChurchId} = household;
      localStorageSet(LocalStorageKey.Household, {...householdWithoutChurchId, ...(church && {churchId: church.id})});
      localStorageRemove(LocalStorageKey.PushChurch);
    }
    const serverChurch = await server_api.getHouseholdChurch(household.token);
    if (serializeChurch(church) !== serializeChurch(serverChurch)) {
      if (serverChurch) {
        serverChurch.mostRecentSyncTimestamp = Date.now();
      }
      localStorageSet(LocalStorageKey.Church, serverChurch);
    }
  } catch { }
}

function serializeChurch(church: ClientChurch | undefined): string | undefined {
  if (!church) {
    return undefined;
  }
  // avoid taking into acount the sync timestamp when comparing church contents
  const {mostRecentSyncTimestamp: _unused, ...churchWithoutTimestamp} = church;
  return JSON.stringify(churchWithoutTimestamp);
}

function clearOldSongLists() {
  const songLists = localStorageGet(LocalStorageKey.WeeklySongList);

  // clear out old data if new song lists are becoming enabled
  if (songLists && songLists.length > 0) {
    if (!(songLists[0] as any).id) {
      localStorageRemove(LocalStorageKey.WeeklySongList);
    }
  }
}

export function getWeeklySongLists(church: ClientChurch, songLists: SongList[]): SongList[] {

  if (church.type === OrganizationType.School) {
    const {termStartDate, termEndDate} = getCurrentStartEndDatesForSchoolTerms(church.schoolTerms);

    if (termStartDate !== minDateAbsolute() && termEndDate !== maxDateAbsolute()) {
      return songLists.filter(songList => {
        return (
          dateFromString(songList.date) >= dateFromString(termStartDate) &&
          dateFromString(songList.date) <= dateFromString(termEndDate) &&
          dateFromString(songList.date) <= dateFromString(todayDate())
        )
      });
    }

    let currentTerm: SongList[] = [];
    let nextTerm: SongList[] = [];

    for (let i = songLists.length - 1; i > -1; i-- ) {
      if (dateFromString(songLists[i].date) <= dateFromString(todayDate())) {
        currentTerm.push(songLists[i]);
        break;
      }
    }

    for (let i = 0; i < songLists.length; i++ ) {
      if (dateFromString(songLists[i].date) > dateFromString(todayDate())) {
        nextTerm.push(songLists[i]);
        break;
      }
    }

    return [...currentTerm, ...nextTerm];
  }

  const lastSundayDate = new Date(
    dateFromString(thisSundayDate()).getFullYear(),
    dateFromString(thisSundayDate()).getMonth(),
    dateFromString(thisSundayDate()).getDate() - 7
  );
  const oneWeekAgoDate = new Date(
    dateFromString(todayDate()).getFullYear(),
    dateFromString(todayDate()).getMonth(),
    dateFromString(todayDate()).getDate() - 7
  );
  const twoWeeksOutDate = new Date(
    dateFromString(todayDate()).getFullYear(),
    dateFromString(todayDate()).getMonth(),
    dateFromString(todayDate()).getDate() + 14
  );

  const hymnOfTheMonth = songLists.filter((songList) => {
    const songListMonth = dateFromString(songList.date).getMonth();
    const currentMonth = dateFromString(todayDate()).getMonth();
    return (
      songList.type === SongListType.HymnOfTheMonth &&
      songListMonth === currentMonth
    );
  });

  const thisSundaySongList = songLists.filter((songList) => {
    return (
      songList.type === SongListType.WorshipService &&
      thisSundayDate() === songList.date
    )
  });

  const lastSundaySongList = songLists.filter((songList) => {
    return (
      songList.type === SongListType.WorshipService &&
      dateStringFromDate(lastSundayDate) === songList.date
    )
  });

  const pastEvents = songLists.filter((songList) => {
    return (
      songList.type === SongListType.Event &&
      dateFromString(songList.date) < dateFromString(todayDate()) &&
      dateFromString(songList.date) >= oneWeekAgoDate
    )
  });

  const todayEvents = songLists.filter((songList) => {
    return (
      songList.type === SongListType.Event &&
      songList.date === todayDate()
    )
  });

  const nearFutureEvents = songLists.filter((songList) => {
    return (
      songList.type === SongListType.Event &&
      dateFromString(songList.date) > dateFromString(todayDate()) &&
      dateFromString(songList.date) <= twoWeeksOutDate
    )
  });

  const allFutureEvents = songLists.filter((songList) => {
    return (
      songList.type === SongListType.Event &&
      dateFromString(songList.date) > dateFromString(todayDate())
    )
  });

  let futureEvents = nearFutureEvents;

  if (!nearFutureEvents.length && allFutureEvents.length) {
    futureEvents = [allFutureEvents[0]];
  }

  return [
    ...hymnOfTheMonth,
    ...thisSundaySongList,
    ...lastSundaySongList,
    ...todayEvents,
    ...futureEvents,
    ...pastEvents
  ];
}

export async function synchronizeWeeklySongLists() {
  await synchronizeChurchWithServer();
  const church = localStorageGet(LocalStorageKey.Church);

  if (!church) {
    localStorageRemove(LocalStorageKey.WeeklySongList);
    return;
  }

  clearOldSongLists();

  const previousSongsForWeek = localStorageGet(LocalStorageKey.WeeklySongList);

  // ignore network errors
  try {
    const earliestDate = minDate([thisMonthDate(), addDayOffset(todayDate(), -7)]);
    const fromDate = church.type === OrganizationType.School ?
      getCurrentStartEndDatesForSchoolTerms(church.schoolTerms).termStartDate : earliestDate;
    const songLists = await server_api.getSongLists(church.id, fromDate);

    const weeklySongLists = getWeeklySongLists(church, songLists);

    // only update storage if something changed
    if (JSON.stringify(weeklySongLists) !== JSON.stringify(previousSongsForWeek)) {
      localStorageSet(LocalStorageKey.WeeklySongList, weeklySongLists);
    }
  } catch {
  }
}

let synchronizeInProgress = false;

export async function synchronizeAllSongLists() {
  if (synchronizeInProgress) {
    return;
  }

  synchronizeInProgress = true;
  try {
    await synchronizeChurchWithServer();
    const church = localStorageGet(LocalStorageKey.Church);
    const household = localStorageGet(LocalStorageKey.Household);
    if (!church || !household) {
      localStorageRemove(LocalStorageKey.AllSongLists);
      localStorageRemove(LocalStorageKey.UpdatedSongListIds);
      return;
    }
    await deprecatedUploadUpdatedSongs(church, household);
    await uploadUpdatedSongLists(church, household);
    await downloadServerSongLists(church, songListUpdatesPending);
  } catch (error: any) {
    if (error.message.includes('403')) {
      // user is not authorized - prevent the app from getting stuck on a server permission error
      localStorageRemove(LocalStorageKey.UpdatedSongListIds);
      await clearAllSongListUpdates();
      const church = localStorageGet(LocalStorageKey.Church);
      if (church) {
        await downloadServerSongLists(church);
      }
    }
  } finally {
    synchronizeInProgress = false;
  }

  if (await songListUpdatesPending()) {
    setTimeout(() => void synchronizeAllSongLists());
  }
}

// TODO(hewitt): Remove 03/2025 along with LocalStorageKey.UpdatedSongListIds, once all pending updates are drained
async function deprecatedUploadUpdatedSongs(church: ClientChurch, household: Household) {
  const updatedSongListIds = localStorageGet(LocalStorageKey.UpdatedSongListIds);
  if (updatedSongListIds.length > 0) {
    const allSongLists = localStorageGet(LocalStorageKey.AllSongLists);
    const updatedSongLists = allSongLists
      .filter(songList => songList.id && updatedSongListIds.includes(songList.id))
      .map(songList => songList.id! >= 0 ? songList : {...songList, id: undefined});
    if (updatedSongLists.length > 0) {
      // TODO(hewitt): how to deal with failure here?
      //               don't want to trash the music leader's updates
      //               also don't want music leader stranded, unable to get latest changes
      //               For now, we can show an asterisk in title bar if not uploaded so we know it is happening
      await server_api.upsertSongLists(church.id, updatedSongLists, isAdminForCurrentChurch(household));
    }
    const songListIdsToDelete = updatedSongListIds
      .filter(id => !updatedSongLists.find(songList => songList.id === id));
    if (songListIdsToDelete.length > 0) {
      await server_api.deleteSongLists(church.id, songListIdsToDelete, isAdminForCurrentChurch(household));
    }
  }
  localStorageRemove(LocalStorageKey.UpdatedSongListIds);
}

// Maps negative song list IDs (brand-new song lists) to server assigned song list IDs.
// Mainly needed for the EventEditor to map the ID of a brand-new event after it successfully uploads to the server.
// Cleared when the event calendar closes.
const songListIdMap = new Map<SongListId, SongListId>();
export function clearSongListIdMap() { songListIdMap.clear(); }
export function mapSongListId(id: number): number {
  if (id >= 0 || !songListIdMap.has(id)) {
    return id;
  }
  return songListIdMap.get(id) as number;
}

async function uploadUpdatedSongLists(church: ClientChurch, household: Household) {
  const now = Date.now();
  const songLists = await getSongListUpdatesBefore(now);
  const updatedLists = songLists.filter(songList => !songList.isDeleted);
  const deletedLists = songLists.filter(songList => songList?.isDeleted && songList.id && songList.id >= 0);
  if (updatedLists.length > 0) {
    const newIds = await server_api.upsertSongLists(
      church.id, removeNegativeIds(updatedLists), isAdminForCurrentChurch(household));
    for (const [index, songList] of updatedLists.entries()) {
      if (songList.id && songList.id < 0) {
        songListIdMap.set(songList.id, newIds[index]);
      }
    }
  }
  if (deletedLists.length > 0) {
    await server_api.deleteSongLists(
      church.id,
      deletedLists.map(({id}) => mapSongListId(id!)),
      isAdminForCurrentChurch(household));
  }
  await removeSongListUpdatesBefore(now);
}

function removeNegativeIds(songLists: DeletableSongList[]): DeletableSongList[] {
  return songLists.map(songList => songList.id && songList.id >= 0 ? songList : {...songList, id: undefined});
}

async function downloadServerSongLists(church: ClientChurch, cancel?: () => Promise<boolean>) {
  const earliestDate = addDayOffset(thisSundayDate(), -(songHistorySizeInWeeks * 7));
  const allSongs = await server_api.getSongLists(church.id, earliestDate);

  // Toss the results at the last minute if song list updates are pending because otherwise the pending changes
  // disappear from LocalStorageKey.AllSongLists and therefore from the liturgy planner.
  // We will eventually update our local storage to match the server, but only after all the updates have
  // been updated.
  if (!cancel || !(await cancel())) {
    localStorageSet(LocalStorageKey.AllSongLists, allSongs);
  }
}

export async function setSongTempoForChurch({church, songSlug, songTempo}: {
  church: ClientChurch;
  songSlug: SongSlug;
  songTempo: number;
}): Promise<void> {
  void server_api.setSongTempoForOrganization({organizationId: church.id, songSlug, songTempo});
  localStorageSet(LocalStorageKey.Church, {...church, songTempos: {...church.songTempos, [songSlug]: songTempo}});
}
