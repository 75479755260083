import {useHousehold} from '../data/use_household';
import {useLocation} from 'react-router-dom';
import {isHymnUrl} from './shared';
import {getPageFromLocation} from './path';
import {Pages} from '../../common/pages';

export function useAllowInAppNotifications() {
  const {household} = useHousehold();
  const location = useLocation();
  const page = getPageFromLocation(location)
  return household && // no household => demo page
    !isHymnUrl(location.search) && // don't interrupt song playback
    page !== Pages.Onboarding && // don't interrupt onboarding process
    page !== Pages.AdminInvite
}
