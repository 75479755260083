import {BackButton, BackButtonPlaceholder} from '../church/shared';
import {ReactNode, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useInternalPage} from '../use_internal_page';
import styled from 'styled-components/macro';
import {useLocalStorage} from '../../../data/use_local_storage';
import {LocalStorageKey} from '../../../data/client_local_storage';
import {maybeUpgradeApp} from '../../../util/app_upgrade';

export const EngineeringAdminWrapper = ({children, title}: {children: ReactNode, title: string}) => {
  useInternalPage();
  const navigate = useNavigate();

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <OuterWrapper key='outer-wrapper'>
      <Header key='header'>
        <BackButton key='back-button' onClick={onBack}/>
        <BackButtonPlaceholder key='back-button-placeholder' onClick={onBack}/>
        <Title key='title'>{title}</Title>
      </Header>
      <ScrollableContent>
        {children}
      </ScrollableContent>
    </OuterWrapper>
  );
}

const OuterWrapper = styled.div`
`;

const HeaderHeight = '3.5rem';

const Header = styled.div`
  height: ${HeaderHeight};
  display: flex;
  align-items: center;
`;

const ScrollableContent = styled.div`
  height: calc(100vh - ${HeaderHeight});
  overflow: scroll;
`;

const Title = styled.h1`
  margin-left: 5px;
  text-align: left;
`;

export const SubHeader = styled.h2`
  text-align: left;
`;

export function useRefreshMusicCaches() {
  const [, setCustomMusicHash] = useLocalStorage(LocalStorageKey.CustomMusicHash);
  const [, setHymnalsHash] = useLocalStorage(LocalStorageKey.HymnalsHash);

  useEffect(() => {
    // redownload all songs to get latest versions
    setHymnalsHash(undefined);
    setCustomMusicHash(undefined);
    void maybeUpgradeApp();
  }, [setCustomMusicHash, setHymnalsHash]);
}
